@use "src/styles/vars";

.other-univers {
    color: black;
    text-align: left;
    border-bottom: 1px solid rgb(126, 123, 123);
    text-transform: uppercase;
    font-weight: 600;
    padding: 25px 20px;
    background-color: #f9f5f0;
    font-size: 14px;
}

.other-actif {
    border-bottom: 1px solid lightgray;
}

.other-actif-closed {
    display: none;
}

.other-univers-item {
    font-weight: 600;
    text-transform: uppercase;
    text-align: left;
    padding: 25px 20px;
    color: vars.$main-color;
    a {
        font-size: 14px;
        color: inherit;
    }
}
.other-univers-item:last-child {
    color: #6a8ea4;
}
