.professor-home-card-wrapper {
    width: 100%;
    height: 100%;

    .professor-home-card-image-wrapper {
        height: 70%;
        width: 100%;
        margin-bottom: 10%;

        .professor-home-card-image {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    .professor-home-card-name {
        margin-bottom: 12px;
        font-size: 20px;
        font-weight: 600;
    }

    .professor-home-card-subject {
        line-height: 25px;
        font-size: 17px;
        width: 70%;
        margin: 0 auto 5%;
    }
}
