@use "src/styles/vars";

.media-filter-desktop-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px 0;
    align-items: center;
    .filter-media-select-title {
        text-transform: uppercase;
        margin-right: 20px;
        font-weight: 600;
    }

    .mediafilter-desktop-item {
        cursor: pointer;
        margin: 0 15px;
        background-color: #f9f5f0;
        border: 1px solid vars.$main-color;
        color: vars.$main-color;
        padding: 7px 7px;
        &.actif {
            background-color: vars.$main-color;
            color: #f9f5f0;
        }
    }
}
