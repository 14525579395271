@use "src/styles/vars";

.addCardAndWishlist-selection-icon-wrapper {
    width: 50%;
    text-align: right;

    .addCardAndWishlist-selection-icon {
        display: inline;
        height: 1.2rem;
        width: 1.2rem;
        margin-right: 5%;
        cursor: pointer;

        // &.cart:hover {
        //     background-color: white;

        // }
    }

    .addCardAndWishlist-selection-icon.heart:hover {
        content: url("https://static.artesane.com/images_react/full_heart.png");
        height: 1.2rem;
        width: 1.2rem;
        margin-right: 5%;
    }
}

@media (max-width: 1000px) {
    .addCardAndWishlist-selection-icon.heart {
        margin-right: 20%;
    }
    .addCardAndWishlist-selection-icon.heart:hover {
        margin-right: 20% !important;
        height: 1.2rem;
        width: 1.2rem;
    }
}
