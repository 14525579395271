@use "src/styles/vars";

.password-confirm-wrapper {
    position: fixed;
    top: 0;
    /* right: 0; */
    left: 0;
    z-index: 3;
    width: 100vw;
    height: 100vh;
    background: #00000078;

    .validate {
        color: #80b074;
        font-weight: 600;
    }

    .error {
        color: #d73307;
        font-weight: 600;
    }

    .password-form-wrapper {
        position: absolute;
        top: 25%;
        left: 35%;
        background: white;
        width: 30%;
        .password-spinner {
            width: 100%;
            position: absolute;
            height: 100%;
            z-index: 1;
            background: #ffffff73;
        }
        form {
            display: block;

            .input-wrapper {
                padding: 15px 50px;
            }
        }

        .password-to-closed {
            position: absolute;
            right: 20px;
            top: 10px;
            font-size: 20px;
            cursor: pointer;
        }

        h1 {
            font-size: 20px;
            text-transform: uppercase;
            color: vars.$main-color;
            margin: 30px auto;
        }

        form {
            width: 100%;

            .input-information {
                font-size: 14px;
                color: grey;
                text-align: left;
                padding: 0 50px;

                &.error {
                    color: #d73307 !important;
                }
            }

            button {
                cursor: pointer;
                border: none;
                background-color: vars.$main-color;
                color: white;
                padding: 10px 30px;
                font-size: 16px;
                text-align: center;
                text-decoration: none;
                font-family: vars.$font;
                margin: 20px auto;
            }
        }
    }
}

@media (max-width: 1000px) {
    .password-form-wrapper {
        left: 6% !important;
        width: 90% !important;

        h1 {
            margin: 40px auto !important;
            font-size: 16px !important;
        }
    }
}
