.zoom-carousel-wrapper {
    background-color: #061f4bd3;
    z-index: 5;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    .zoom-carousel-close-buttn {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        background-color: #061f4b;
        color: #fffcf8;
        padding: 10px 10px;
        text-transform: uppercase;
        font-size: 14px;
    }

    .zoom-carousel-prev-btn,
    .zoom-carousel-next-btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 45%;
        font-size: 25px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #061f4b;
        color: #fffcf8;
    }
    .zoom-carousel-next-btn {
        right: 20px;
    }
    .zoom-carousel-prev-btn {
        left: 20px;
    }

    .zoom-carousel-image-wrapper {
        width: 80%;
        height: 90%;
        margin: 2% auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

@media (max-width: 1000px) {
    .zoom-carousel-wrapper {
        display: none;
    }
}
