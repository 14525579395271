@use "src/styles/vars";

.home-catalogue-wrapper {
    display: flex;
    margin-top: 3%;
    padding: 5%;
    text-align: start;
    justify-content: space-between;
    height: 500px;
    background-color: #fffcf8;

    .home-catalogue-image-wrapper {
        width: 50%;
        height: 100%;

        .home-catalogue-image {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .home-catalogue-text-wrapper {
        width: 54%;
        padding: 1% 5%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;

        .home-catalogue-title {
            font-size: 30px;
            text-transform: uppercase;
            font-weight: 600;
            color: vars.$main-color;
            letter-spacing: 5px;
            height: 20%;
            width: 100%;
            margin-bottom: 5%;

            .home-catalogue-title-firstpart {
                margin-bottom: 2%;
            }
        }
    }
}

@media (max-width: 600px) {
    .home-catalogue-wrapper {
        padding: 12% 5%;
    }
    .home-catalogue-title {
        font-size: 23px !important ;
    }

    .home-catalogue-taxons-wrapper {
        .home-catalogue-taxons {
            font-size: 17px;
        }
    }
}

@media (min-width: 1600px) {
    .home-catalogue-wrapper {
        height: 850px;
    }
}
