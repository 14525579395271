@use "src/styles/vars";

.account-menu-wrapper,
.account-menu-wrapper-closed {
    .account-menu-responsive-burger {
        display: none;
    }

    width: 17%;
    text-align: left;
    padding: 30px 30px;
    .account-menu-item {
        font-size: 17px;
        margin: 8px 0;
        width: 11.5rem;

        .deconnect {
            display: flex;
            align-items: center;
            img {
                width: 21px;
                margin-left: 12px;
            }
        }

        .account-link-active {
            background-color: vars.$main-color;
            color: white;
            display: block;
        }

        a {
            width: 100%;
            height: 100%;
            margin: auto;
            padding: 10px 10px;
            display: block;
        }

        div {
            padding: 10px 10px;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }
}

@media (max-width: 1000px) {
    .account-menu-wrapper {
        animation: fadeIn 0.6s;
        display: block;
        width: 90%;
        margin: 20px auto;
        background: white;
        padding: 0;

        .account-menu-item {
            width: 100%;

            a {
                width: 94%;
                margin: 0%;
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
