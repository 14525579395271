.paiement-tag-wrapper {
    display: inline-flex;
    .paiement-tag-logo-oney {
        width: 55px;
        margin-right: 15px;
        vertical-align: baseline;
        object-fit: contain;
    }
    .oney-active {
        color: #86bd28;
    }
    .oney-desactive {
        color: rgb(179, 178, 178);
    }
}
