.sort-items-wrapper-closed {
    display: none;
}
.sort-items-wrapper {
    background-color: white;

    .filters-sort-under-items-wrapper {
        background-color: white;
    }
}
@media (min-width: 1000px) {
    .sort-items-wrapper {
        border: 1px solid lightgray;
        animation: fadeIn 0.5s;
        position: absolute;
        right: -36%;
        top: 80px;
        width: 20%;
        z-index: 1;
        .filters-button-wrapper {
            display: none;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
