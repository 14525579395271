@use "src/styles/vars";

.filters-sort-under-items-closed {
    display: none;
}

@media (min-width: 1000px) {
    .filters-sort-under-items-open {
        animation: fadeIn 0.3s;
        position: absolute;
        width: 318%;
        z-index: 1;
        height: 218px;
        overflow: auto;
        // display: flex;
        // flex-wrap: wrap;
        text-align: left;
        background-color: white;
        border: 1px solid lightgrey;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
