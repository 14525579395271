@use "src/styles/vars";

.add-card-bar-wrapper {
    z-index: 1;
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    height: 70px;
    align-items: center;
    font-size: 13px;
    text-transform: uppercase;
    .add-card-wrapper {
        position: relative;
        cursor: pointer;
        border: 2px solid vars.$main-color;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background-color: vars.$main-color;
        height: 100%;
        width: 55%;
        &.still {
            background-color: #80b074;
            border: 7px solid #80b074;
        }
        &.outStock {
            background-color: rgb(198, 198, 198);
            border-color: rgb(198, 198, 198);
        }
    }

    .add-offer-and-wishlist-wrapper {
        cursor: pointer;
        width: 45%;
        height: 100%;
        display: flex;
        border: 2px solid #d99606;
        background-color: white;
        .add-offer-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70%;
            height: 100%;
            border-right: 2px solid vars.$main-color;
            background-color: white;
            color: vars.$main-color;
        }

        .add-wishList-wrapper {
            a {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30%;
            height: 100%;
            // border-top: 2px solid vars.$main-color;
            border-bottom: 2px solid vars.$main-color;
            background-color: white;

            .add-card-logo {
                margin-right: 0px;
            }
        }
    }

    .add-card-logo {
        width: 20px;
        display: inline;
        margin-right: 7px;
    }
    .spinner-product-wrapper {
        width: 100%;
        height: 100%;
        z-index: 3;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.493);
        .spinner-wrapper {
            height: 100%;
        }
    }
}

@media (min-width: 1000px) {
    .add-card-bar-wrapper {
        bottom: 2px;
        width: 35%;
        margin: auto;
        height: 7.5%;

        .add-card-wrapper {
            // padding: 10px 0 ;
            width: 100%;
        }

        .add-offer-and-wishlist-wrapper {
            width: 100%;
            .add-offer-wrapper {
                width: 70%;
                height: 100%;
            }

            .add-wishList-wrapper {
                width: 30%;
                // padding: 10px 0;
                height: 100%;
                border: none;
            }
        }
    }
}

@media (min-width: 1600px) {
    .add-card-bar-wrapper {
        font-size: 23px;
        height: 80px;
        bottom: 0;

        .add-card-logo {
            width: 30px;
        }
    }
}
