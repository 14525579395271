.menucards-wrapper {
        
    .alice-carousel__dots {
        display: none;
        background-color: white;
    }
    .alice-carousel__stage-item {
        transform: translateX(-50px);
    }
}
    .alice-carousel .alice-carousel__prev-btn-item,
    .alice-carousel .alice-carousel__next-btn-item {
    opacity: 0;
    transition: opacity 0.2s ease;

    }

    .alice-carousel:hover .alice-carousel__prev-btn-item,
    .alice-carousel:hover .alice-carousel__next-btn-item {
    opacity: 1;
    color: white !important;
    }


    .alice-carousel__prev-btn [data-area]::after, .alice-carousel__next-btn [data-area]::after{

        width: 45px;
        height: 45px;
        color: white;
        background-color: rgba(11, 11, 11, 0.485);
        border-radius: 50%;
        position: relative;
        display: inline-flex; 
        justify-content: center;
        align-items: center;
        font-size: 30px;
    }
    .alice-carousel__prev-btn {
        left: 0.5%;
    }

    .alice-carousel__next-btn .alice-carousel__next-btn-wrapper .alice-carousel__next-btn-item:hover {
        color: white;
    }
    @media (max-width: 600px) {

        .menucards-wrapper {
            padding-bottom: 1rem;

            .alice-carousel__dots {
                display: block !important;
            }
        }
    

    }