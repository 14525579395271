@use "src/styles/vars";

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
    padding: 1rem 2rem;
    max-width: 100%;
    font-size: vars.$paragraph-header;
    position: sticky;
    top: 39px;
    background: white;
    z-index: 3;
    box-shadow : 0px 4px 18px 0px rgba(105, 105, 105, 0.54);


    .universe-items {
        width: 80%;
        text-align: center;
        a{
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 600;
            margin-right: 30px;

            &:hover {
                color: vars.$main-color;
            }
        }
    }
  

    .prepage-menu-responsive-burger {
        display: none;
    }
    .prepage-menu-wrapper-closed {
        display: none;
    }

    .dropDown-menu-wrapper {
        width: 33%;
        .dropdownMenu {
            width: 8rem;
            border: 1px solid lightgrey;
            padding: 12px 25px;
            span {
                margin-left: 2rem;
            }
        }
    }
}

@media (max-width: 600px) {
    .header {
        flex-wrap: wrap;
        height: 7rem;
        flex-direction: column-reverse;
        font-size: 18px;
    }
}

@media (min-width: 600px) and (max-width: 1000px) {
    .header {
        a {
            width: 33%;
            margin-left: 33%;
        }
    }
}

@media (max-width: 1000px) {
    .header {
        // padding-top: 1rem;
        position: relative;
        top: 0;

        .dropDown-menu-wrapper {
            display: none;
        }

        // .burger-prepage-menu-wrapper {
        //     position: absolute;
        //     top: 0px;
        //     left: 20px;
        .prepage-menu-responsive-burger {
            display: block;
            position: absolute;
            top: 26px;
            left: 20px;

            div {
                width: fit-content;
                margin: auto;
            }

            .burger-menu-cross-item {
                width: 35px;
                height: 8px;
                border-top: 2px solid grey;
            }
        }
        // }

        .prepage-menu-responsive-burger-open {
            display: block;
            position: absolute;
            top: 15px;
            right: 9px;
            z-index: 3;
            background: #f9f5f0;
            padding: 0;
            width: 30px;
            height: 30px;
            .burger-item-one {
                border-bottom: 2px solid grey;
                transform: rotate(-45deg) translate(-7px, -2px);
                width: 30px;
                height: 8px;
            }
            .burger-item-three {
                border-bottom: 2px solid grey;
                transform: rotate(45deg) translate(-4px, -4px);
                width: 30px;
                height: 8px;
            }

            .burger-item-two {
                display: none;
            }
        }
    }
    .prepage-menu-wrapper {
        display: block;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        background: #f9f5f0;
        font-weight: 600;
        padding: 0;

        .prepage-menu-item {
            text-align: left;
            width: 100%;
            text-transform: uppercase;

            a {
                padding: 20px;
                border-bottom: solid lightgray 1px;
                width: 94%;
                margin: 0%;
                display: block;
            }
        }
    }
    .prepage-menu-wrapper-closed {
        display: none;
    }
}
