.confirm-popup-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background: #00000069;
    z-index: 2;

    .popup-wrapper {
        background-color: #f9f5f0;
        // height: 45%;
        padding: 30px 0;
        width: 30%;
        display: block;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;
        .popup-cross {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }

        .confirm-message {
            font-size: 18px;
            color: #80b074;
            font-weight: 600;
            display: block;
            padding: 0 30px;
            margin: 60px auto;
        }
        .confirm-button {
            cursor: pointer;
            display: block;
            width: fit-content;
            margin: 20px auto;
            // margin-left: auto;
            background-color: #d99606;
            color: white;
            padding: 10px;
            text-transform: uppercase;
            // font-size: 18px;
            text-align: center;
        }
        .close-button {
            cursor: pointer;
            display: block;
            width: fit-content;
            margin: 20px auto;
            // margin-left: auto;
            background-color: #d99606;
            color: white;
            padding: 10px;
            text-transform: uppercase;
            // font-size: 18px;
            text-align: center;
        }
    }
}

@media (max-width: 600px) {
    .confirm-popup-wrapper {
        .popup-wrapper {
            padding: 0 20px;
            width: 90%;
            // height: 40%;
        }
    }
}

@media (min-width: 600px) and (max-width: 1000px) {
    .confirm-popup-wrapper {
        .popup-wrapper {
            padding: 0 20px;
            width: 70%;
            // height: 40%;
        }
    }
}
