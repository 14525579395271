@use "src/styles/vars";

.recommandations-wrapper {
    padding: 35px 0;
    .recommandations-title {
        text-transform: uppercase;
        margin: auto;
        color: vars.$main-color;
        font-weight: 600;
        font-size: 20px;
        padding-bottom: 15px;
        span {
            display: block;
        }

        span:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .recommandation-slider-wrapper {
        &.flex {
            display: flex;
            justify-content: center;
        }
        .recommendation-slider-card-wrapper {
            padding: 0 20px;
        }

        // .alice-carousel__stage-item {
        //     width: 75% !important;
        //     height: 80% !important;
        // }
    }

    .alice-carousel__dots {
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 99% !important;

        .alice-carousel__dots-item {
            background-color: gray;
            width: 6px !important;
            height: 6px !important;
            border-radius: 0%;
            margin-right: 7px !important;

            &.__active {
                background-color: lightgray !important;
            }
        }
    }

    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
        display: none;
    }
}

@media (min-width: 1000px) {
    .recommendation-slider-card-wrapper {
        max-width: 400px;
    }
    .recommandations-wrapper {
        width: 97%;
        margin: auto;

        .recommandations-title {
            margin: 20px auto;
            font-size: 28px;
        }

        .alice-carousel__prev-btn,
        .alice-carousel__next-btn {
            display: block;
            .alice-carousel__next-btn-item {
                right: -40%;
            }
            .alice-carousel__prev-btn-item {
                right: 13%;
            }
        }
    }
}

@media (min-width: 1600px) {
    .recommandations-wrapper {
        width: 73% !important;
        margin: auto;
    }
}
