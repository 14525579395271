@use "src/styles/vars";

.new-password-wrapper {
    width: 60%;
    margin: auto;
    min-height: 90vh;
    .spinner-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 3;
        background-color: rgba(255, 255, 255, 0.507);
    }
    .input-information {
        font-size: 14px;
        color: grey;
        text-align: left;
        padding: 0 50px;
        width: 50%;
        margin: auto;

        &.error {
            color: #d73307 !important;
        }
    }

    .new-password-title {
        margin: 30px auto;
        text-transform: uppercase;
        font-weight: 600;
        width: fit-content;
        border-bottom: 1px solid black;
    }

    .new-password-information {
        padding: 20px;
        font-size: 18px;
        text-align: left;
    }

    button {
        cursor: pointer;
        border: none;
        background-color: vars.$main-color;
        color: white;
        padding: 10px 30px;
        font-size: 16px;
        text-align: center;
        text-decoration: none;
        font-family: vars.$font;
        margin: 30px auto;
    }

    .input-wrapper {
        width: 50%;
        margin: auto;
        img {
            top: 46px;
        }
    }
    .spinner-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 3;
        background-color: rgba(255, 255, 255, 0.507);
    }
}

@media (max-width: 1000px) {
    .new-password-wrapper {
        width: 90%;
    }
    .input-wrapper {
        width: 90% !important;
        margin: auto;
    }
}
