@use "src/styles/vars";

.shipment-address-wrapper {
    form {
        padding: 0;
    }
    .title-wrapper {
        margin: 0px !important;
    }
    .payment-item-wrapper {
        display: flex;
        margin: 2em 0em;
        margin: 10px 0 !important;
        display: flex;
        margin: 1em 0em;
        width: 95%;
        min-height: 0px;
        background: transparent;
        padding: 0em;
        border: none;
        border-radius: 0rem;
        box-shadow: none;
        transition: box-shadow 0.1s ease;
        flex-wrap: wrap;
    
        
    
        label {
            cursor: pointer;
        }
    
        input {
            margin-top: 11px !important;
        }
    
        .oney-logo {
            cursor: pointer;
            width: 8rem;
        }
    
    
        
    }
    
}

