.title-wrapper {
    text-transform: uppercase;
    padding-top: 30px;
    font-size: 20px;
    line-height: 32px;
    font-weight: 300;
}

@media (min-width: 1000px) {
    .title-wrapper {
        padding-top: 10px;
    }
}

@media (min-width: 1600px) {
    .title-wrapper {
        padding-top: 40px;
        font-size: 27px;
    }
}
