@use "src/styles/vars";

.player-logo {
    width: 15%;
}

.newsletter-home-wrapper {
    padding: 5% 3%;
    height: 361px;
    display: flex;
    flex-direction: row-reverse;

    .newsletter-home-player {
        width: 45%;
        height: 100%;

        .newsletter-home-player > div {
            max-width: 100%;
        }
        video {
            object-fit: contain !important;
        }
    }

    .newsletter-home-left-side-wrapper {
        width: 55%;
        // padding: 2% 0;

        .newsletter-home-title {
            text-transform: uppercase;
            font-size: 36px;
            color: vars.$main-color;
            font-weight: 600;
            letter-spacing: 2px;
        }

        .newsletter-home-title:first-of-type {
            margin-bottom: 2%;
        }

        .newsletter-home-subtitles {
            margin: 5% 0;
            font-size: 25px;
            font-weight: 600;
        }

        .newsletter-home-subtitle:first-of-type {
            margin-bottom: 1%;
        }

        .newsletter-home-form-wrapper {
            width: 100%;
            // height: 3rem;

            .newsletter-home-input-wrapper {
                height: 100%;

                .newsletter-home-form {
                    height: 100%;

                    ul {
                        list-style: none;
                        text-align: left;
                        padding-inline-start: 0;
                        width: 40%;
                        justify-content: space-between;
                        display: flex;
                        margin: 10px auto;
                    }

                    .newsletter-home-input {
                        font-size: vars.$normal-font-size;
                        background-color: #f9f5f0;

                        font-weight: 700;
                        border: none;
                        text-align: center;
                        // padding-left: 2%;
                        height: 40px;
                        width: 40%;

                        &::placeholder {
                            color: rgb(196, 188, 188) !important;
                            font-family: vars.$font !important;
                        }
                    }

                    .newsletter-home-button {
                        font-size: vars.$normal-font-size;
                        cursor: pointer;
                        text-transform: uppercase;
                        color: #fff;
                        background-color: vars.$main-color;
                        border: none;
                        height: 40px;
                        width: 40%;
                    }
                }
            }
        }
        .newsletter-home-confidential {
            font-size: 13px;
            color: #b2b2b1;
            width: 55%;
            margin: 5% auto;

            a {
                color: inherit;
                text-decoration: underline;
            }
        }
    }
}

@media (min-width: 600px) and (max-width: 1000px) {
    .newsletter-home-input {
        width: 60% !important;
        height: 50% !important;
        padding: 2%;
    }

    .newsletter-home-button {
        margin-top: 20px;
        width: 65% !important;
        height: 50% !important;
        padding: 2%;
    }
    .newsletter-home-confidential {
        width: 100% !important;
        margin: 6% auto !important;
    }

    .newsletter-home-player {
        width: 100%;
        height: 16rem;
    }
}

@media (max-width: 600px) {
    .newsletter-home-wrapper {
        height: fit-content;
        display: block;
        padding: 10% 3%;

        .newsletter-home-left-side-wrapper {
            width: 100%;
            .newsletter-home-form-wrapper {
                height: 13rem;

                .newsletter-home-form {
                    // height: 60% !important;

                    ul {
                        display: block !important;
                    }
                    .newsletter-home-input {
                        width: 60% !important;
                        height: 2.5rem !important;
                    }

                    .newsletter-home-button {
                        margin-top: 20px;
                        width: 65% !important;
                        height: 2.5rem !important;
                    }

                    ul {
                        list-style: none;
                        // display: flex;
                        text-align: left;
                        padding-inline-start: 70px;
                    }
                }
                .newsletter-home-title {
                    font-size: 25px !important;
                }

                // .newsletter-home-button {

                // }
                .newsletter-home-confidential {
                    width: 100% !important;
                    margin: -6% auto !important;
                }
            }
        }

        .newsletter-home-player {
            width: 100%;
            height: 16rem;
        }
    }
}

@media (min-width: 1600px) {
    .newsletter-home-wrapper {
        height: 600px;
    }
}
