.adresseinsert-wrapper {
    padding-left: 30px;
    .adress-wrapper_title {
        text-align: left;
        text-decoration: underline;
    }
    .address-wrapper {
        padding-top: 30px;

        .input-wrapper {
            justify-content: flex-end;
            flex-direction: row-reverse;
            padding: 0;
            margin: 20px 0;

            .personal-input  {
                margin: 0;
                padding: 8px;
            }
        }

        .input-wrapper .personal-input[type=checkbox]::before {
            width: 11px;
            height: 11px;
        }
        .address-wrapper-side {
            display: flex;
            align-items: flex-start;
            text-align: left;

            .address-side {
                width: 30%;

                .button-transparent {
                    border: 1px solid black;
                    width: fit-content;
                    padding: 6px;
                    cursor: pointer;

                    &:hover {
                        color:#fffcf8 ;
                        background-color: black;
                    }
                }

                .adress-detail {
                    margin: 10px 0;
                }
            }

            .address-side.left {
                .adress-detail {
                    margin: 15px 0;
                }
            }
        }
    }
}


@media (max-width: 1000px) {

    .adresseinsert-wrapper {
        padding:  0 30px!important;

        .address-wrapper-side {
            justify-content: space-between;
            .address-side {
                width: 45% !important;
            }
        }

        
    }
   
}