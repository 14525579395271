@use "src/styles/vars";

.orders-card-wrapper {
    width: 95%;
    min-height: 240px;
    display: flex;
    background-color: #fffcf8;
    margin-bottom: 30px;

    .orders-card-wrapper-left {
        width: 20%;
        position: relative;
        .bold {
            font-weight: 600;
        }

        .green {
            color: #80b074;
            text-decoration: underline;
        }
        padding: 5px 50px;
        border-right: vars.$main-color 2px solid;
        margin: 20px 0;
        text-align: left;

        div {
            margin-bottom: 20px;
        }

        .orders-card-number {
            color: vars.$main-color;
        }
        span {
            display: block;
            margin-bottom: 5px;
        }

        .spinner-wrapper {
            position: absolute;
            top: 0;
            z-index: 2;
            height: 100%;
            width: 80%;
            background: #ffffff96;
        }

        .orders-card-invoice {
            cursor: pointer;
            span {
                text-decoration: underline;
            }
        }
    }

    .orders-card-wrapper-right {
        text-align: left;
        padding: 5px 30px;
        margin: 20px 0;
        width: 65%;
        position: relative;

        .bold {
            font-weight: 600;
        }
        .url {
            text-decoration: underline;
        }

        .orders-card-article-wrapper {
            width: 100%;

            .orders-card-article-title {
                font-weight: 600;
            }

            .orders-card-article-button {
                margin-top: 20px;
                color: white;
                background-color: vars.$main-color;
                width: fit-content;
                padding: 8px 10px;
                cursor: pointer;
            }

            .orders-card-articles-wrapper {
                width: 100%;
                height: 100%;
                background-color: #fffcf8;
                top: 0;
                z-index: 1;
                position: absolute;
                overflow: auto;

                .order-card-article-title {
                    font-weight: 600;
                    margin-bottom: 15px;
                }
                .cross {
                    position: inherit;
                    right: 30px;
                    cursor: pointer;
                }
                .orders-card-articles-items {
                    display: flex;
                    flex-wrap: wrap;
                    border-top: 1px solid black;
                    width: 90%;

                    .orders-card-article-name {
                        width: 50%;
                    }
                    span {
                        display: block;
                        margin-right: 20px;
                        padding: 7px 0;
                    }
                }
            }
            .card-spinner-wrapper {
                max-height: 100%;

                .spinner-wrapper {
                    height: 100%;
                }
            }
        }

        .orders-card-delivery-information-wrapper {
            margin-top: 20px;
            div:not(:last-child) {
                margin-bottom: 20px;
            }
            div {
                span {
                    margin-right: 10px;
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .user-orders-wrapper {
        width: 100%;

        .orders-card-wrapper {
            flex-direction: column;
            width: 90%;
            margin: 20px auto;

            .orders-card-wrapper-left {
                border-bottom: vars.$main-color 2px solid;
                border-right: none;
                padding: 5px 25px;
                width: 88%;
            }
            .orders-card-wrapper-right {
                padding: 5px 25px;
                width: 88%;

                .orders-card-article-name {
                    width: 54% !important;
                }

                .orders-card-articles-items {
                    width: 90%;
                }
            }
        }
    }
}
