.top-informations-wrapper {
    padding: 6% 10px;
    background-color: #f9f5f0;
    text-align: left;
    position: relative;

    .product-level-and-price-wrapper {
        margin-bottom: 20px;
        .product-level-wrapper {
            display: inline;
        }

        .top-informations-price-wrapper {
            text-align: right;
            display: inline-flex;
            flex-direction: row-reverse;
            width: 46%;
            .lesson-card-selection-price {
                display: flex;
                flex-direction: inherit;
                width: 100%;
                align-items: baseline;

                .lesson-card-selection-final-price {
                    // color: inherit;
                    font-size: 20px;
                    // font-weight: normal;
                    width: 60%;
                    margin-right: 10px;
                }

                .lesson-card-selection-before-price {
                    margin: 0;
                    width: 30%;
                }
            }
        }
    }
}

@media (min-width: 1000px) {
    .top-informations-wrapper {
        background-color: #fff;
        padding: 15px 40px;

        .reduction-wrapper {
            width: fit-content;
        }

        .product-level-and-price-wrapper {
            display: flex;
            flex-direction: column-reverse;
            .product-level-wrapper {
                width: 100%;
                margin-bottom: 20px;
            }

            .top-informations-price-wrapper {
                margin-top: 20px !important;
                width: 100% !important;
                display: flex !important;
                flex-direction: row !important;
                justify-content: flex-start !important;
                flex-wrap: wrap !important;
                font-size: 23px;

                .lesson-card-selection-price {
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: left;
                    width: 70% !important;

                    .lesson-card-selection-price-without-reduction {
                        font-size: 28px;
                    }

                    .lesson-card-selection-before-price {
                        margin-left: 10px;
                        width: 40%;
                        font-size: 26px;
                    }
                    .lesson-card-selection-final-price {
                        width: 40%;
                        margin-left: 10px;
                        font-size: 26px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1600px) {
    .top-informations-wrapper {
        .lesson-card-selection-price-without-reduction {
            font-size: 30px !important;
        }

        .lesson-card-selection-before-price {
            margin-left: 10px;
            width: 40%;
            font-size: 28px !important;
        }
        .lesson-card-selection-final-price {
            width: 40%;
            margin-left: 10px;
            font-size: 28px !important;
        }
    }
}
