@use "src/styles/vars";

.qui-sommes-nous-wrapper {
    text-align: left;
    background-color: #fffcf8;
    padding: 50px 220px;
    line-height: 20px;

    p {
        text-indent: 30px;
    }

    h1 {
        color: vars.$main-color;
        text-transform: uppercase;
        font-size: 25px;
        margin-bottom: 50px;
        text-align: center;
    }
    h2 {
        position: static;
    }
    .qui-sommes-nous-top-description {
        text-indent: 30px;
        margin-bottom: 80px;
        text-align: justify;
        padding: 0 200px;
    }

    .qui-sommes-nous-informations-wrapper {
        display: flex;
        margin-bottom: 70px;
        text-align: justify;
        .qui-sommes-nous-image-wrapper {
            display: flex;
            align-items: center;
            img {
                width: 550px;
                object-fit: cover;
            }
        }

        .qui-sommes-nous-text-information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 30px;
        }

        h2 {
            text-align: center;
            color: vars.$main-color;
            font-size: 23px;
            font-weight: 600;
            text-transform: uppercase;
            line-height: 30px;
        }
    }
}

@media (max-width: 1000px) {
    .qui-sommes-nous-wrapper {
        padding: 40px;

        .qui-sommes-nous-top-description {
            padding: 0;
        }

        .qui-sommes-nous-informations-wrapper {
            flex-wrap: wrap;
            margin-bottom: 50px;

            .qui-sommes-nous-image-wrapper {
                margin: auto;

                img {
                    width: 270px;
                }
            }

            .qui-sommes-nous-text-information {
                padding: 0;
            }
        }
    }
}

@media (min-width: 1600px) {
    .qui-sommes-nous-wrapper {
        padding: 50px 300px;
    }
}
