.cart-wrapper {
    background-color: #fffcf8;
    .cart-title {
        padding: 10px 0;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 15px;
        border-bottom: 1px solid black;
    }

    .card-and-total-order-wrapper {
        display: flex;
        margin: 20px 0;
        display: flex;
        margin: 20px 0;
        padding: 25px 50px;
    
        .item-cards-wrapper {
            width: 72%;
            border-right: 2px solid black;
            padding-left: 30px;
        }
    }
}

@media (max-width: 1000px) {

    .card-and-total-order-wrapper {
        flex-wrap: wrap;
        padding: 30px !important;
        .item-cards-wrapper {
            width: 100% !important;
            border-bottom: 2px solid black !important;
            border-right: none !important;
            min-height: 0 !important;
            padding-left: 0px !important;
        }
    }
   
}
