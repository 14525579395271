@use "src/styles/vars";

.nouveaute-et-pack-wrapper {
    padding-top: 15px;
    .nouveaute-tag,
    .pack-tag {
        text-transform: uppercase;
        font-size: 12px;
        margin-right: 10px;
        color: white;
        padding: 5px 6px;
    }

    .nouveaute-tag {
        background-color: vars.$green;
    }

    .pack-tag {
        background-color: #061f4b;
    }
}

@media (min-width: 1000px) {
    .nouveaute-et-pack-wrapper {
        padding-top: 1px;
    }
}
