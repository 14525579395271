@use "src/styles/vars";

.professor-public-card-wrapper {
    text-align: left;
    width: 90.5%;
    padding: 0 70px;
    display: flex;
    margin-bottom: 80px;
    .professeur-public-card-image-wrapper {
        img {
            width: 350px;
            height: 335px;
            object-fit: contain;
        }
    }

    .professeur-public-card-description {
        padding: 49px 75px;
        background-color: #fffcf8;
        width: 55%;
        line-height: 20px;
        // margin: 0 24px;
        .button-anchor {
            cursor: pointer;
            color: #fff;
            width: fit-content;
            text-transform: uppercase;
            background-color: vars.$main-color;
            padding: 0.6rem;
            margin: 30px auto;
            border: 2px solid vars.$main-color;

            a:visited {
                color: inherit;
            }

            &:hover {
                color: vars.$main-color !important;
                background-color: #fff !important;
            }
        }

        .professeur-public-card-title {
            font-weight: 600;
            margin-bottom: 10px;
        }

        .professeur-public-card-texte {
            text-align: justify;
            // text-indent: 20px;
        }
    }

    .professeur-public-card-networks {
        padding: 30px 50px;
        background-color: #fffcf8;
        width: 25%;
        border-left: 1.6px solid black;

        .professeur-public-card-network {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            .professeur-public-card-logo-wrapper {
                a {
                    height: 100%;
                    img {
                        width: 35px;
                    }
                }
            }

            .professeur-public-card-network-texte {
                a {
                    margin-left: 15px;
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .professor-public-card-wrapper {
        flex-wrap: wrap;
        margin: auto;
        padding: 0;
        width: 80%;
        .professeur-public-card-image-wrapper {
            width: 100%;

            img {
                width: 100%;
            }
        }

        .professeur-public-card-description {
            width: 100%;
            padding: 20px 15px;

            .professeur-public-card-texte {
                text-align: left;
            }
        }

        .professeur-public-card-networks {
            width: 100%;
        }
    }
}
