@use "src/styles/vars";

.secure-payment-wrapper {
    line-height: 20px;
    text-align: justify;
    min-height: 90vh;
    background-color: #fffcf8;
    padding: 50px 300px;
    h1 {
        color: vars.$main-color;
        text-transform: uppercase;
        font-size: 25px;
        margin-bottom: 70px;
    }

    .secure-payment-images {
        display: flex;
        width: 100%;
        // margin-bottom: 40px;

        img {
            width: 100px;
        }
    }
}

@media (max-width: 1000px) {
    .secure-payment-wrapper {
        padding: 50px 50px;
    }
}
