@use "src/styles/vars";

.modify-address-wrapper {
    height: 100vh;
    width: 100vw;
    z-index: 4;
    background-color: #00000075;
    position: fixed;
    top: 0;
    left: 0;

    .spinner-wrapper {
        z-index: 2;
        height: 100%;
        background-color: #ffffff57;
    }

    .modify-popup-wrapper {
        position: fixed;
        left: 25%;
        top: 1%;
        width: 50%;
        background-color: #fffcf8;
        .error {
            text-align: center;
            color: #d73307;
            font-weight: 600;
        }
        .moify-address-title {
            text-align: center;
            text-transform: uppercase;
            padding: 20px 20px;
            color: vars.$main-color;
            font-weight: 600;
        }
        form {
            text-align: center;
            .input-wrapper {
                padding: 0.5% 20px;
            }
            .cross {
                position: absolute;
                right: 20px;
                top: 20px;
                cursor: pointer;
            }

            .submit-button {
                cursor: pointer;
                border: none;
                background-color: vars.$main-color;
                color: white;
                padding: 10px 30px;
                font-size: 16px;
                text-align: center;
                text-decoration: none;
                font-family: vars.$font;
                margin: 20px 0;
            }
            .input-wrapper {
                & .error {
                    text-align: left;
                    font-weight: 300;
                }
            }
            .input-information {
                text-align: left;
                color: gray;
                padding: 0.5% 20px;
                margin-bottom: 10px;
                &.error {
                    text-align: left;
                    font-weight: 300 !important;
                    color: #d73307 !important;
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .modify-popup-wrapper {
        width: 100% !important;
        top: 0 !important;
        left: 0 !important;
    }
}
