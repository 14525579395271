@use "src/styles/vars";
@import "react-alice-carousel/lib/scss/alice-carousel.scss";

.product-slider-desktop-wrapper {
    width: 100%;

    .product-slider-image-video-wrapper {
        width: 85%;
        margin: auto;
        height: 100%;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .product-slider-video-icon {
            position: absolute;
            width: 96px;
            object-fit: contain;
            left: 45%;
        }

        .product-slider-image-video-hover {
            cursor: pointer;
            width: 100%;
            height: 100%;
            z-index: 1;
            top: 0;
            position: absolute;
            background-color: #061f4b5d;

            .product-slider-image-video-hover-button {
                margin: auto;
                position: absolute;
                top: 45%;
                left: 45%;
                text-transform: uppercase;
                color: #f9f5f0;
                background-color: #061f4b;
                width: fit-content;
                padding: 10px 10px;
            }
        }
    }
    .product-slider-desktop-caroussel-miniature {
        padding: 20px 0;
        height: 20%;
        width: 85%;
        margin: auto;

        .alice-carousel__next-btn {
            right: -3.5%;
        }
        .alice-carousel__prev-btn {
            left: -3%;
        }

        .react-player__preview {
            background-size: contain !important;
        }
        .products-slider-image-wrapper {
            // width: 89%;
            height: 130px;
            cursor: pointer;

            &:hover {
                border: 2px vars.$main-color solid;
            }

            img {
                margin: auto;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media (min-width: 1600px) {
    .product-slider-desktop-caroussel-miniature {
        width: 73% !important;
    }
    .product-slider-image-video-wrapper {
        width: 73% !important;
    }
}
