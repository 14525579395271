@use "src/styles/vars";

.item-top-wrapper {
    font-weight: 600;
    margin-bottom: 20px;
    span {
        margin: 0 2px;
    }
    color: #80b074;
}
.item-card-wrapper {
    position: relative;
    margin: 20px auto;
    background-color: #fffcf8;
    // display: flex;
    // align-items: center;
}

.item-bottom-wrapper {
    position: relative;

    background-color: #fffcf8;
    display: flex;
    align-items: center;
    // padding: 20px ;
    .image-wrapper {
        position: relative;
        width: 10%;
        img {
            width: 100%;
            object-fit: cover;
        }
        .solde {
            position: absolute;
            top: 2%;
            right: 2%;
            background-color: #d73307;
            color: #fff;
            font-weight: 600;
            padding: 3%;
        }
    }

    .gift-wrapper {
        width: 16%;
        text-align: left;
        position: relative;
        .gift-information-text {
            background-color: #fffcf8;
            padding: 10px;
            border: 1px solid #d99606;
            position: absolute;
            width: 15rem;
            height: fit-content;
            z-index: 1;
        }

        .information-gift {
            border: solid 1px #d99606;
            text-align: center;
            height: 15px;
            width: 15px;
            display: block;
            border-radius: 50%;
            top: 0%;
            left: 60%;
            line-height: 16px;
            position: absolute;
            font-size: small;
            cursor: pointer;
        }
        img {
            width: 25px;
        }
        span {
            font-weight: 600;
            color: vars.$main-color;
        }
    }
    .name-wrapper {
        padding: 0 20px;
        text-align: left;

        width: 30%;
        .product-title {
            font-weight: 600;
        }
        .product-variant {
            margin-top: 15px;
        }
    }
    .quantity-wrapper {
        select {
            width: 50px;
            margin-bottom: 15px;
            option {
            }
        }

        .delete {
            cursor: pointer;
            font-weight: 600;
            text-decoration: underline;
            .mobile {
                display: none;
            }
        }
    }

    .price-wrapper {
        margin-left: 30px;
        width: 26%;
        display: flex;
        justify-content: right;
        .after-price {
            width: 50%;
            font-weight: 600;
        }
        .before-price {
            width: 50%;
            text-decoration: line-through;
        }
    }
    .spinner-cart {
        width: 100%;
        height: 100%;
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.493);
        .spinner-wrapper {
            height: 100%;
        }
    }
}

@media (max-width: 1000px) {
    .item-card-wrapper {
        width: 90%;
        flex-wrap: wrap;

        .name-wrapper {
            width: 56%;
        }
        .price-wrapper {
            width: 50%;
        }
    }
}

@media (max-width: 600px) {
    .item-bottom-wrapper {
        flex-wrap: wrap;

        .price-wrapper {
            width: 90%;
        }
        .image-wrapper {
            position: relative;
            width: 25%;
        }
    }
    .delete {
        .mobile {
            display: block !important;
            width: 25px;
        }

        .desktop {
            display: none;
        }
    }
}
