@use "src/styles/vars";

.footer-wrapper-responsive {
    padding: 20px 0;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #e7dacb;
    color: black;
    .footer-newsletter-icon-wrapper-responsive {
        width: 80%;
        border-bottom: solid 1px black;
        .footer-newsletter-wrapper-responsive {
            .footer-newsletter-title-responsive {
                text-transform: uppercase;
                font-size: 13px;
                font-weight: 600;
                margin-bottom: 15px;
            }
            .newsletter-footer-form-responsive {
                width: 80%;
                margin: auto;

                ul {
                    list-style-type: none;

                    li {
                        text-align: left;
                    }
                }

                div {
                    margin-top: 20px;
                }
                .newsletter-footer-input-responsive {
                    width: 97%;
                    height: 32px;
                    background-color: #e7dacb;
                    border: 1px solid white;
                    &::placeholder {
                        color: black;
                        font-family: vars.$font !important;
                    }
                }
                .newsletter-footer-button-responsive {
                    font-size: 12px;
                    height: 32px;
                    width: 100%;
                    text-transform: uppercase;
                    font-family: vars.$font !important;
                    background-color: white;
                    border: 1px solid white;
                    color: black;
                    font-weight: 600;
                }
            }
        }
        .footer-icon-wrapper-responsive {
            margin: 35px;
            .footer-icon-responsive {
                display: inline-block;
                .footer-logo {
                    width: 35px;
                }

                .qualiopi {
                    width: 200px;
                    height: 110px;
                }
            }
        }
    }
    .footer-accordion-wrapper-responsive {
        padding: 27px 0;
        width: 80%;
        border-bottom: 1px solid black;
        .footer-accordion-responsive {
            .footer-items-title {
            }
            .footer-items {
                a {
                    color: inherit;
                }
            }
        }
    }

    .footer-univers-responsive {
        width: 80%;
        margin: 30px 0;
        border-bottom: solid 1px black;

        .footer-univers-link:not(:last-child) {
            margin-bottom: 5px;
            a {
                color: inherit;
            }
        }
        .footer-univers-link {
            a {
                color: inherit;
            }
        }
        .footer-univers-link:last-child {
            margin-bottom: 30px;
        }
    }
    .footer-logo-wrapper {
        margin-bottom: 30px;
        .footer-logo-img {
            width: 200px;
        }
    }
    .footer-copyright {
    }
}

// @media (max-width:1000px) {

//     .footer-top-wrapper {
//         flex-wrap: wrap;
//         align-items: center;
//         justify-content: center;
//         width: 80% !important;
//         .footer-univers {
//         width: 100% !important;
//         border: none !important;

//             .footer-logo-wrapper {
//                 display: none;
//             }
//         }

//         .footer-items-wrapper {
//             width: 100% !important;

//             .footer-items-colonne-wrapper {
//                 flex-wrap: wrap !important;

//                 .footer-items-colonne {
//                     width: 100% !important;
//                 }
//             }

//             .footer-items-under {
//                 flex-wrap: wrap;
//                 border-bottom: 1px solid white;

//                 .footer-item-under-follow-wrapper {
//                     width: 100% !important;
//                     padding: 5px !important;
//                     text-align: center !important;

//                 }
//             }
//         }
//     }

// }
