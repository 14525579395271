@use "src/styles/vars";

.conditions-offres-wrapper {
    text-align: justify;
    background-color: #fffcf8;
    padding: 50px 300px;
    line-height: 20px;
    min-height: 90vh;

    h1 {
        color: vars.$main-color;
        text-transform: uppercase;
        font-size: 25px;
        margin-bottom: 50px;
    }
}

@media (max-width: 1000px) {
    .conditions-offres-wrapper {
        padding: 50px 50px;
    }
}
