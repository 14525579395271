@use "src/styles/vars";

.subscriptions-wrapper {
    width: 90%;
    min-height: 100vh;
    background-color: #fffcf8;
    .spinner-subscriptions-spinner {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: #ffffff80;
        z-index: 2;
    }

    .subscriptions-items-wrapper {
        margin: auto;
        justify-content: center;
        margin: 70px 50px;
        .subscriptions-title {
            color: vars.$main-color;
            text-transform: uppercase;
            font-size: 20px;
            margin-top: 30px;
            border-bottom: 1px solid vars.$main-color;
            font-weight: 600;
            width: 33%;
            text-align: left;
            padding: 10px;
        }

        .subscriptions-item {
            display: flex;
            align-items: center;
            margin: 55px 0;
            text-align: left;

            .subsription-item-title {
                width: 32%;
                text-transform: uppercase;
                font-weight: 600;
            }
        }

        .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
            color: vars.$main-color;
        }
        .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
            + .MuiSwitch-track {
            background-color: vars.$main-color;
        }
    }
}

@media (max-width: 1000px) {
    .subsription-item-title {
        width: 100% !important;
    }
}
