.materiel-wrapper {
    padding-bottom: 15px;

    .materiel-item:not(:last-child) {
        margin-bottom: 7px;
    }
}

@media (min-width: 1000px) {
    .materiel-wrapper {
        min-width: 65%;
        padding-right: 30px;
    }
}
