@use "src/styles/vars";

.professeur-catalogue-uppertext {
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 600;
    color: vars.$main-color;
    margin: 50px 0;

    .professeur-catalogue-downtext {
        text-transform: none;
        font-size: 17px;
        color: black;
        margin-top: 10px;
    }
}
