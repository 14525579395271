@use "src/styles/vars";

.professor-name {
    color: vars.$main-color;
    font-size: 18px;
    padding: 10px 0;
}

@media (min-width: 1000px) {
    .professor-name {
        padding-top: 5px;
    }
}

@media (min-width: 1000px) {
    .professor-name {
        font-size: 23px;
        padding-top: 18px;
    }
}
