@use "src/styles/vars";

.filters-and-sort-wrapper {
    display: flex;
    .filters-and-sort-button {
        width: 50%;
        height: 44px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: vars.$main-color;
        color: white;
        border: white solid 1px;
    }
}
.more-filters-button {
    display: none;
}
.filters-or-sort-items {
    max-height: 300px;
    overflow-y: auto;
}

@media (min-width: 1000px) {
    .filters-and-sort-wrapper {
        width: 9%;
        display: flex;
        justify-content: flex-end;
        padding: 2% 2%;

        .filters-and-sort-button {
            width: 100%;
            background-color: white;
            color: black;
            border: 1px lightgrey solid;
            cursor: pointer;
        }
    }
    .filters-or-sort-items {
        width: 68%;
        display: flex;
        flex-wrap: wrap;
        padding: 2% 2%;
        padding-bottom: 0%;
        max-height: unset;
        overflow: unset;
        position: relative;
    }

    .more-filters-button {
        display: flex;
        padding: 2% 1%;
        font-weight: 600;
        width: 13%;
        justify-content: center;
        text-align: center;

        div {
            width: 90%;
            display: flex;
            height: 44px;
            align-items: center;
            background-color: #061f4b;
            color: #f9f5f0;
            border: 2px solid #f9f5f0;
            padding: 2% 1%;
            justify-content: center;
            cursor: pointer;
            &:hover {
                background-color: #f9f5f0;
                color: #061f4b;
                border: 2px solid #061f4b;
            }
        }
    }
}
