.highlights-wraper {
    text-align: left;
    padding: 35px 7px;

    .highlights-item {
        display: flex;
        align-items: center;
        vertical-align: middle;
        .hightlight-texte {
            height: 100%;
            display: inline !important;
            position: relative !important;
            font-size: 17px !important;
            text-transform: none !important;
            font-weight: 400 !important;
        }
        img {
            width: 40px;
        }
    }
}
@media (min-width: 1000px) {
    .highlights-wraper {
        padding: 20px 40px !important;
    }
}
@media (min-width: 1600px) {
    .highlights-wraper {
        font-size: 20px;
        .highlights-item {
            margin: 10px 0;
        }
    }
}
