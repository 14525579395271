@use "src/styles/vars";

.livraison-wrapper {
    width: 72%;
    border-right: 2px solid black;
    background-color: #fffcf8;

    label {
        cursor: pointer;
    }

    .spinner-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 90vh;
        z-index: 3;
        background-color: #fffcf8;
    }

    .return.cart {
        color: white;
        background-color: vars.$main-color;
        padding: 10px;
    }
    .livraison-title {
        text-transform: uppercase;
        font-weight: 600;
        border-bottom: 2px solid black;
        width: fit-content;
        margin: 10px auto;
    }

    .order-address-wrapper {
        padding: 0px 20px;
        text-align: left;
        display: flex;
        margin-top: 20px;

        form {
            width: 100%;
            .input-information {
                padding: 0px 20px;
                color: gray;
                margin-bottom: 20px;

                &.error {
                    color: #d73307 !important;
                }
            }
            .submit-button {
                display: block;
                margin: 40px auto ;
                margin-left: auto;
                background-color: #d99606;
                color: white;
                width : 20%;
                padding: 18px;
                text-transform: uppercase;
                font-size: 14px;
                text-align: center;
                border : 1px solid vars.$main-color;
                cursor: pointer;
            }

            .flex {
                display: flex;
            }
        }
        .adresse {
            .name {
                font-weight: 700;
                margin: 20px 0;
            }
            .chose-address-select {
                display: flex;
                flex-direction: column;
                width: 84%;
                margin-bottom: 20px;

                label {
                    margin-bottom: 10px;
                }
                select,
                option {
                    padding: 10px 5px;
                    font-size: 14px;
                    font-family: vars.$font;
                }
            }
            .billing {
                .input-wrapper.checkbox {
                    align-items: center;
                    margin-left : 20px;
                    label {
                        margin-bottom: 0px !important;
                    }
                    input {
                        margin : 0px;
                    }
                }

            }

            .input-wrapper.checkbox {
                flex-direction: row-reverse;
                padding: 10px 0;
                justify-content: flex-end;
            }
            .adress-formulaire {
                width: 90%;

                .input-wrapper {
                    padding: 7px 20px;
                    width: 50%
                }
                form {
                    .input-wrapper {
                        flex-direction: column;
                    }
                }
            }
        }
    }
    .return {
        cursor: pointer;
        width: fit-content;
        &:hover {
            border-bottom: 1px solid black;
        }
    }

    .shipment-address-wrapper {
        div {
            font-weight: 600;
            margin: 20px 0;
        }

        form {
            margin-top: 50px;
            text-align: left;

            .shipping-item-wrapper {
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                label {
                    margin-left: 10px;
                    font-weight: 300;
                }
            }

            button {
                cursor: pointer;
                border: none;
                background-color: vars.$main-color;
                color: white;
                padding: 10px 30px;
                font-size: 16px;
                text-align: center;
                text-decoration: none;
                font-family: vars.$font;
                margin: 50px auto;
                width: fit-content;
                display: block;
                text-transform: uppercase;
            }
        }

        .shipment-wrapper {
            margin-top: 50px;
            text-align: left;


            .payment-item {
                text-transform: none;
            }

            .shipping-item-wrapper {
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                label {
                    margin-left: 10px;
                    font-weight: 300;
                }
            }

            button {
                cursor: pointer;
                border: none;
                background-color: vars.$main-color;
                color: white;
                padding: 10px 30px;
                font-size: 16px;
                text-align: center;
                text-decoration: none;
                font-family: vars.$font;
                margin: 50px auto;
                width: fit-content;
                display: block;
                text-transform: uppercase;
            }
        }
    }
}

@media (max-width: 1000px) {
    .livraison-wrapper {
        border: 0;
        width: 100% !important;

        
        .shipment-wrapper  {

            font-size: 12px;

        }
        .flex {
            display: block !important;
        }
        *
        .submit-button {
            width: 75% !important;
        }

        .order-address-wrapper {
            form {
                flex-wrap: wrap;
                .adresse {
                    width: 100%;

                    .adress-formulaire {
                        width: 100%;

                        .input-wrapper{
                            padding: 5px 0px;
                        }
                    }
                }
            }
        }
    }
}
