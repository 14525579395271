.under-navbar-searchBar {
    margin-right: 2rem;
    height: 100%;
    width: 17%;
    background-color: #f4f1f1;
    float: right;

    .searchBar {
        height: 97%;
        width: 100%;
        position: relative;

        .searchBar-form {
            height: 100%;
            width: 100%;

            .searchBar-input {
                height: 100%;
                border: none;
                width: 100%;
                padding: 1px 15px;
                background-color: #f4f1f1;
            }

            .search-bar-button {
                width: 1.3rem;
                position: absolute;
                top: 18%;
                right: 0;
                cursor: pointer;
                border: none;
                height: 2rem;

                .searchBar-icon {
                    width: 1.3rem;
                    position: absolute;
                    top: 30%;
                    right: 2%;
                    cursor: pointer;
                }
            }
            button {
                -webkit-appearance: none;
                background-color: #f4f1f1 !important;
            }
        }
    }
}
@media (max-width: 1000px) {
    .under-navbar-searchBar {
        width: 80%;
    }
}
