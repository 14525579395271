@use "src/styles/vars";

.inscription-wrapper {
    display: flex;
    padding-top: 50px;

    .inscription-hidden {
        visibility: hidden;
        position: fixed;
    }
    .spinner-wrapper {
        position: fixed;
        width: 100%;
        height: 100vh;
        background: #fffcf880;
        top: 0;
        left: 0;
        z-index: 2;
    }
    .left-part {
        width: 49%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .image-wrapper {
            width: 100%;
            height: 22.2rem;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .right-part {
        height: 100%;
        padding: 0 50px;
        width: 60%;
        .title-inscription {
            text-transform: uppercase;
            font-weight: 600;
            margin-bottom: 20px;
        }

        .form-inscriptions-wrapper {
            // text-align: left;

            form {
                input[type="date"]::before {
                    content: attr(data-placeholder);
                    color: #999;
                }
                .parts-wrapper {
                    display: flex;
                    .left-part {
                        width: 40%;
                        padding: 20px 20px;

                        .input-wrapper {
                            padding: 13px 20px;
                        }
                        .input-wrapper.checkbox {
                            flex-direction: row-reverse;
                            justify-content: flex-end;
                        }
                        .input-information {
                            font-size: 14px;
                            color: grey;
                            text-align: left;
                            padding: 0 20px;

                            &.error {
                                color: #d73307 !important;
                            }
                        }
                    }
                    .right-part {
                        width: 40%;
                        padding: 45px 20px;
                        .under-title {
                            font-weight: 600;
                            margin-bottom: 20px;
                        }
                        .input-wrapper {
                            label {
                                font-weight: 300;
                            }
                            padding: 5px 20px;
                            flex-direction: row-reverse;
                            justify-content: flex-end;
                        }
                    }
                }

                .inscription-text {
                    margin: 20px 0;
                }
                button {
                    cursor: pointer;
                    border: none;
                    text-transform: uppercase;
                    background-color: vars.$main-color;
                    color: white;
                    padding: 10px 20px;
                    font-size: 15px;
                    text-align: center;
                    text-decoration: none;
                    font-family: vars.$font;
                    // margin-left: 20px;
                    margin: 30px 0;
                }

                .captcha-wrapper {
                    width: fit-content;
                    margin: auto;
                    background: lightgray;
                    border-radius: 5px;
                    display: flex;

                    .input-wrapper.checkbox {
                        flex-direction: row-reverse;
                        padding: 20px 0;
                        label {
                            margin-bottom: 0;
                        }
                        &.capcha.error {
                            label {
                                color: #d73307 !important;
                            }
                        }
                        &.validate {
                            color: #80b074;
                        }
                    }
                    img {
                        width: 37px;
                        object-fit: contain;
                        padding: 0 14px;
                    }
                }
            }
        }
        .new-wrapper {
            margin: 20px 0;
            div {
                display: inline-block;
                margin-right: 20px;
                margin-bottom: 10px;
                a {
                    border-bottom: 2px solid black;
                }
            }

            .uppercase {
                text-transform: uppercase;
                font-weight: 600;
            }
        }
    }
}

@media (max-width: 1000px) {
    .inscription-wrapper {
        .left-part {
            display: none;
        }

        .right-part {
            width: 100%;
            padding: 0%;
            .title-inscription {
                text-transform: uppercase;
                font-weight: 600;
            }

            .form-inscriptions-wrapper {
                // text-align: left;

                form {
                    .parts-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        .left-part {
                            display: block;
                            width: 100%;
                            padding: 20px 20px;

                            .input-wrapper {
                                width: 80%;
                                margin: auto;
                            }
                            .input-information {
                                width: 80%;
                                margin: auto;
                            }
                        }
                        .right-part {
                            width: 100%;
                            padding: 20px;
                        }
                    }

                    .inscription-text {
                        margin: 20px 0;
                    }
                    button {
                        cursor: pointer;
                        border: none;
                        text-transform: uppercase;
                        background-color: vars.$main-color;
                        color: white;
                        padding: 10px 20px;
                        font-size: 15px;
                        text-align: center;
                        text-decoration: none;
                        font-family: vars.$font;
                        // margin-left: 20px;
                        margin: 30px 0;
                    }
                }
            }
            .new-wrapper {
                margin: 20px 0;
                div {
                    display: inline-block;
                    margin-right: 20px;
                    margin-bottom: 10px;
                    a {
                        border-bottom: 2px solid black;
                    }
                }

                .uppercase {
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }
        }
    }
}
