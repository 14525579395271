@use "src/styles/vars";

.dropdown-item {
    list-style-type: none;
    background-color: #ffff;
    display: block;
    padding: 6% 3%;
    border-bottom: 1px solid rgb(218, 215, 215);
    color: black;
}
.dropdown-item:hover {
    color: vars.$main-color;
}

.dropdown-items-active {
    animation: fadeIn 0.8s;
    position: absolute;
    width: 13.5rem;
    top: 100%;
    left: 0%;
    z-index: 999 !important;
    border-top: 1px solid rgb(218, 215, 215);

    a {
        z-index: 999 !important;
        position: relative;
    }
}

.dropdown-items {
    display: none;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 1000px) {
    .dropdown-items-active {
        width: 9rem;
    }
}
