.error-popup-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background: #00000069;
    z-index: 2;

    .popup-wrapper {
        background-color: #f9f5f0;
        height: 30%;
        width: 30%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;
        .popup-cross {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }

        .error-message {
            font-size: 18px;
            color: #d73307;
            font-weight: 600;
            display: block;
        }
    }
}

@media (max-width: 600px) {
    .error-popup-wrapper {
        .popup-wrapper {
            padding: 0 20px;
            width: 90%;
        }
    }
}

@media (min-width: 600px) and (max-width: 1000px) {
    .error-popup-wrapper {
        .popup-wrapper {
            padding: 0 20px;
            width: 70%;
        }
    }
}
