@use "src/styles/vars";

.research-wrapper {
    min-height: 90vh;
    background-color: #fffcf8;

    position: relative;

    .spinner-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: #fffcf888;
    }

    .research-title {
        font-weight: 600;
        text-transform: uppercase;
        width: fit-content;
        border-bottom: 2px solid black;
        margin: 30px auto;
        padding-top: 30px;
    }
    .research-words {
        color: vars.$main-color;
    }
    .number-result {
        text-align: left;
        margin: 50px;
        padding: 0 50px;
    }

    .research-products {
        padding: 2% 0;
        min-height: 350px;
        background-color: #f9f5f0;
        .research-product-card-wrapper {
            width: 80%;
            margin: 10% auto;
        }
    }
}

@media (min-width: 600px) and (max-width: 1000px) {
    .research-products {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 2% 0;
        .research-product-card-wrapper {
            width: 45% !important;
            margin: 2% 2% !important;
        }
    }
}

@media (min-width: 1000px) {
    .research-products {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 2% 1%;
        // margin-top: 1%;
        // justify-content: space-around;
        .research-product-card-wrapper {
            width: 23% !important;
            margin: 1% 1% !important;
        }
    }
}

@media (min-width: 1600px) {
    .research-products {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 2% 10% !important ;
        // margin-top: 1%;
    }
}
