.reviews-wrapper {
    display: flex;
    // flex-wrap: wrap;
    padding: 4%;
    .review-wrapper-title {
        width: 20%;
        font-size: 50px;
        text-transform: uppercase;
        font-weight: 600;
        text-align: left;
        display: block !important;

        div {
            width: 50%;
        }

    }

    .reviews-items-wrapper:not(:last-child)  {
        padding: 0 30px;
    }
    .reviews-items-wrapper:last-child  {
        padding-left: 30px;
    }
    .reviews-items-wrapper {
         display: flex;
         width: 25%;
        .reviews-item {

          .review-rating {
            text-align: left;
             img {
                width: 6rem;
             }
          }
          .reviews-title {
             font-weight: 600;
             margin: 20px 0 ;
          }

          .review-content {
            text-align: justify;
            height: 10rem;
          }

          .review-author {
            text-align: right;
            margin-top: 17px;
            margin-right: 10px;          }
        }
    }
}

@media (max-width: 600px) {

    .reviews-wrapper {
        flex-wrap: wrap !important;
        .alice-carousel {
            margin: 10px 0;
        }

        .alice-carousel__dots {
            background-color: white;;
        }

        .review-wrapper-title {
            width: 100% !important;
            margin: 0.8rem;
            font-size: 30px !important;
           
        }
    
        .reviews-items-wrapper {
            width: 93% !important;
            padding: 0px 20px !important;
        }
    }
    
}

@media (min-width: 601px)  and (max-width : 1000px) {

    .reviews-wrapper {
        flex-wrap: wrap !important;

        .review-wrapper-title {
            width: 47% !important;
            margin-bottom: 20px;
            font-size: 30px !important;
            display: flex !important;
            align-items: center;
            justify-content: center;
        }
    
        .reviews-items-wrapper {
            width: 40% !important;
        }
    }
    
}
