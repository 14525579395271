.seotext-wrapper {
    margin: 5% auto;
    width: 70%;
    position: relative;
    text-align: justify;
    line-height: 25px;
    text-indent: 40px;
    h1 {
        text-indent: 0;
        text-align: center;
        margin-bottom: 5%;
        text-transform: uppercase;
        font-size: 25px;
    }
}

@media (max-width: 1000px) {
    .seotext-wrapper {
        width: 80%;
        h1 {
            text-indent: 0;
            text-align: center;
            margin-bottom: 5%;
            text-transform: uppercase;
            font-size: 15px !important;
        }
    }
}
