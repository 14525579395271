@use "src/styles/vars";

.my-profil-wrapper {
    width: 76%;
    min-height: 100vh;
    background-color: #fffcf8;
    padding: 30px;

    .spinner-myprofil-spinner {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: #ffffff80;
        z-index: 2;
    }

    .my-profil-form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .my-profil-wrapper-left {
            width: 29%;
            border-right: 2px solid black;
            padding: 20px;
        }

        .my-profil-wrapper-right {
            width: 63%;
            padding: 20px;

            .textarea-wrapper {
                display: flex;
                flex-direction: column;
                padding: 20px;

                label {
                    text-align: left;
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 7px;
                    padding-left: 7px;
                }
                textarea {
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    border-bottom: 2px solid black;
                    font-family: vars.$font !important;
                    font-size: 16px;
                    padding: 7px 7px;
                    background-color: #fffcf8;
                }
            }
            .my-profil-bottom-right {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-end;
                justify-content: space-between;
                .my-profil-bottom-inputs {
                    width: 80%;
                }

                .submit-button {
                    height: fit-content;
                    cursor: pointer;
                    border: none;
                    background-color: vars.$main-color;
                    color: white;
                    padding: 10px 30px;
                    font-size: 16px;
                    text-align: center;
                    text-decoration: none;
                    font-family: vars.$font;
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .my-profil-wrapper {
        width: 100%;
        padding: 0;

        .my-profil-wrapper-left {
            width: 95% !important;
            border-right: none !important;
        }

        .my-profil-wrapper-right {
            width: 95% !important;

            .my-profil-bottom-right {
                flex-direction: column;
                align-items: center !important;
                .my-profil-bottom-inputs {
                    width: 100% !important;
                }

                button {
                    margin-top: 20px;
                }
            }
        }
    }
}
