@use "src/styles/vars";

.filter-choice-slider-wrapper {
    margin: auto auto;
    height: 100%;
    width: 100%;
    position: relative;
    & > * {
        margin: auto auto;
        padding-top: 15%;
        //    flex-direction: column;
        color: black;
    }

    .filter-choice-slider-min-price {
        position: absolute;
        left: -6px;
        bottom: -15px;
    }
    .filter-choice-slider-max-price {
        position: absolute;
        right: -23px;
        bottom: -15px;
    }

    .css-1v5z18m {
        position: relative;
        span {
            color: vars.$main-color;
        }
    }
}

@media (max-width: 1000px) {
    .filter-choice-slider-wrapper {
        background-color: #f9f5f0;
        padding: 7% 0;

        & > * {
            padding: 0 0;
        }
    }
}
