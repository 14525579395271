.logo-maintenance {
    width: 20rem;
    margin: 2rem 0;
}

@media (max-width: 600px) {
    .logo-maintenance {
        width: 10rem;
    }
}
