.top-arrow-wrapper {
    cursor: pointer;
    animation: fadeIn 0.8s;
    position: fixed;
    background-color: #061f4ba8;
    color: #fffcf8;
    bottom: 12%;
    right: 20px;
    z-index: 6;
    transform: rotate(-90deg);
    font-size: 22px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.displayNone {
    display: none;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
