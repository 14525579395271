.title-wrapper {
    border-bottom: 2px solid black;
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    .title-text {
        margin: 0px !important;
    }
}

@media (max-width: 1000px) {
    .title-wrapper {
        text-align: center;
    }
}
