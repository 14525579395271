@import "react-alice-carousel/lib/scss/alice-carousel.scss";

.home-lesson-slider-wrapper {
    background-color: #f9f5f0;
    max-width: 100%;
    padding: 0% 3%;
    padding-top: 3%;
    .flex {
        display: flex;
        padding-top: 2.3rem;
        justify-content: center;
    }

    .title {
        text-transform: uppercase;
        font-size: 25px;
        font-weight: 800;
        margin-bottom: 40px; 
        &:hover {
            animation: none;
        }
    
    }
    .home-lesson-slider-button-wrapper {
        width: 23%;
        margin: 2.4% auto;
    }

    .alice-carousel__stage-item .__active {
        height: 569px;

        .lesson-card-selection-link {
            width: 100%;
            height: 100%;
        }
    }
    .alice-carousel__dots {
        display: none;
    }

}

.home-lesson-slider-wrapper.small {
    height:550px !important
}

.home-lesson-slider-wrapper.normal {
    height: 730px;
}



.home-lesson-slider-wrapper .alice-carousel__stage-item {
    padding-right: 0.8rem;
    padding-left: 0.8rem;
}

.home-lesson-slider-wrapper .alice-carousel__prev-btn-item {
    right: 26% !important;
}

.home-lesson-slider-wrapper .alice-carousel__next-btn-item {
    left: 24% !important;
}
@media (max-width: 600px) {

    .home-lesson-slider-wrapper .alice-carousel__wrapper {
        padding-top: 4%;
    }
    .home-lesson-slider-wrapper {
        height: 720px !important;
        padding: 10% 3%;

        .home-lesson-slider-button-wrapper {
            width: 100% !important;
        }


        .alice-carousel__dots {
            display: block;
            position: absolute;
            bottom: -7px;
            left: 0;
            width: 99% !important;
    
            .alice-carousel__dots-item {
                background-color: gray;
                width: 6px !important;
                height: 6px !important;
                border-radius: 0%;
                margin-right: 7px !important;
            }
        }
    }
}

@media (min-width: 1600px) {
    .home-lesson-slider-wrapper {
        padding: 3% 15%;
    }
}
