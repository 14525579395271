@use "src/styles/vars";


.numbers-wrapper {
    padding: 3% ;
    color: vars.$main-color;
    text-transform: uppercase;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    background-color: #fffcf8 ;
    justify-content: space-around;

    .numbers {
        margin-bottom: 10px;
        font-size: 30px;
    }
    .small {

    }

}

@media (max-width: 1000px)  {
 .numbers-wrapper {
    font-size: 12px;
    flex-wrap: wrap;

    .numbers-item {
        width: 50%;
        margin-bottom: 30px;
    }
    .numbers {
        font-size: 25px;
        margin-bottom: 5px !important;
    }
 }

 .numbers-wrapper > :nth-child(-n+2) {
    margin-top: 30px;
}

}