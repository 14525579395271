.logo {
    width: 28rem;
    text-align: center;
}

.logo img {
    width: 344px;
}

.logo-scroll img {
    width: 235px;
}

//Responsive

@media (max-width: 768px) {
    .logo {
        margin: auto;
        width: 80%;
        img {
            width: 200px;
        }
    }

    .logo-scroll {
        margin: auto;
        // width: 80%;
        img {
            width: 200px;
        }
    }
}

@media (min-width: 768px) and (max-width: 1000px) {
    .logo {
        margin: auto;
        width: 80%;
        img {
            width: 200px;
        }
    }

    .logo-scroll {
        margin: auto;
        // width: 80%;
        img {
            width: 200px;
        }
    }
}
