@use "src/styles/vars";

.cgv-wrapper {
    text-align: justify;
    background-color: #fffcf8;
    padding: 50px 300px;
    line-height: 20px;

    h1 {
        text-align: center;
        color: vars.$main-color;
        text-transform: uppercase;
        font-size: 25px;
        margin-bottom: 50px !important;
    }
    h2 {
        margin-top: 70px;
        color: vars.$main-color;
        text-transform: uppercase;
        position: static;
    }

    h3 {
        color: vars.$main-color;
        text-transform: uppercase;
    }
    p {
        text-indent: 30px;
    }
}

@media (max-width: 1000px) {
    .cgv-wrapper {
        padding: 50px 50px;
    }
}
