@use "src/styles/vars";

@import "react-alice-carousel/lib/scss/alice-carousel.scss";

.home-professor-slider-link {
    text-decoration: none;
    color: black;
}

.home-professor-slider-wrapper {
    color: black;
    background-color: #fffcf8;
    padding: 30px 2%;

    .alice-carousel__stage-item {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
    }
    .alice-carousel__dots {
        display: none;
    }

    .home-professor-slider-button {
        width: 23%;
        margin: auto;
        margin-bottom: 20px;
    }
}
.home-professor-slider-title {
    margin-left: 1.8rem;
    text-transform: uppercase;
    text-align: left;
    font-size: 36px;
    letter-spacing: 7px;
    font-weight: 700;
    color: vars.$main-color;
    margin-bottom: 2%;
}

.home-professor-slider-wrapper .alice-carousel__stage-item {
    height: 500px;
}

.home-professor-slider-wrapper .alice-carousel__prev-btn-item {
    right: 26% !important;
}

.home-professor-slider-wrapper .alice-carousel__next-btn-item {
    left: 24% !important;
}

@media (max-width: 600px) {
    .home-professor-slider-button {
        width: 90% !important;
        height: 67% !important;
    }
    .home-professor-slider-title {
        font-size: 30px;
        text-align: center;
        margin: 20px 0 !important;
    }
}

@media (min-width: 1600px) {
    .home-professor-slider-wrapper {
        padding: 2% 15%;
    }
}
