.oney-simulation-wrapper {
    position: relative;
    text-align: left;
    font-family: Poppins, Arial, sans-serif !important;
    line-height: 1.25;
    letter-spacing: normal;
    color: grey;
    z-index: 2;
    .oney-wrapper {
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: left;
        cursor: pointer;

        img {
            width: 30%;
        }

        div {
            margin: 0 10px;
        }
    }

    .oney-popin-wrapper {
        z-index: 1;
        max-width: 20em;
        background-color: #fff;
        padding: 10px;
        position: absolute;
        top: 0;
        left: calc(-20em - 44px);
        transform: translateY(calc(10px - 33.3333%));
        background-color: white;
        border: 1px solid #81bc00;

        .close-oney {
            height: 40px;
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
        }

        .oney-popin-content {
            img {
                margin-right: 0.5em;
            }

            .oney-title {
                text-transform: uppercase;
                color: #81bc00;
                margin: 5px 0;
            }

            .section_with_fees {
                align-items: center;
                display: flex;

                & > * {
                    box-sizing: border-box;
                }

                img {
                    width: auto;
                }
            }
        }
    }

    .oney-popin-wrapper:after {
        content: "";
        height: 0;
        width: 0;
        border-top: 20px solid #0000;
        border-bottom: 20px solid #0000;
        display: inline-block;
        position: absolute;
        top: calc(33.3333% - 10px);
        right: -20px;
        border-left: 20px solid #81bc00;
    }
}

@media (max-width: 1000px) {
    .oney-wrapper {
        img {
            width: auto !important;
        }
    }

    .oney-popin-wrapper {
        left: 0% !important;
    }
}
