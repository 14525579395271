@use "src/styles/vars";


.mondial-relay_wrapper {
    z-index: 4;
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    background: #00000091;
    top: 0;
  
    .spinner-wrapper {
      background-color: inherit;
    }
  
    .cross {
      cursor: pointer;
      position: fixed;
      background: white;
      padding: 10px;
      right: 2%;
      top: 2%;
      &:hover {
        background: black !important;
        color: white;
      }
    }
  .widget-and-selection-wrapper{
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
    #Zone_Widget {
        .MR-Widget  {
          margin: 0px;
        }
      }
    
      .selected-point-relay {
          border: solid 1px #ddd !important;
          background-color: white;
          text-align: center;
          padding: 12px 0;
          width: 99%;
        
    
        p {
          font-size: 16px;
          margin-bottom: 10px;
        }
        p.name {
          color : #96154a;
          width: fit-content;
          margin: auto;
          padding: 5px;
          font-weight: 600;
        }
    
        button {
          font-family: vars.$font;
          font-size: 15px;
          background-color: lightgray;
          color: white;
          border: none;
          padding: 10px 20px;
          cursor: not-allowed;
    
          &:not([disabled]) {
            cursor: pointer;
            background-color: #28a745;
          }
    
          &:hover:enabled {
              background-color: vars.$main-color;
  
          }
        }
      }
      
  }

  }
  
  @media (max-width: 800px) {
    #Zone_Widget {
        position: relative;
        
        // left: 0%!important;
        // top: 6% !important;  // Ajuster l'espacement sur mobile
      }
  
    .selected-point-relay {
        // left: 0% !important;
        // top: 5% !important;
        
        font-size: 12px; // Réduire la taille de texte
      }
  }
  