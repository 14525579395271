@use "src/styles/vars";

.chapter-menu {
    background-color: #fffcf8;
    width: 92%;
    overflow: auto;
    padding: 4%;
    font-size: 17px;
    position: relative;
    min-height: 95%;
    .cross {
        position: absolute;
        right: 7px;
        top: 0;
        cursor: pointer;
    }

    .cours-title {
        margin-bottom: 20px;
        padding: 10px;
        // border: 1px solid vars.$main-color;
        text-transform: uppercase;
        color: vars.$main-color;
        font-weight: 600;
        font-size: 20px;
    }
    .chapters-items {
        text-align: left;
        .time {
            text-transform: lowercase;
            // font-style: italic;
            margin-left: 5px;
            color: rgba(2, 2, 2, 0.26);
            font-weight: 300;
        }
        .player-icon {
            width: 14px;
            margin-right: 5px;
        }
        .chapter-item-lecon {
            width: fit-content;
            border-bottom: 3px solid #fffcf8;
            margin: 15px 0;
            cursor: pointer;
            font-weight: 600;
            &::after {
                display: block;
                content: "";
                width: 0%;
                height: 3px;
                background-color: vars.$main-color;
                transition: width 0.3s;
            }
            &:hover::after {
                width: 100%;
            }
            &:hover {
                color: vars.$main-color;
            }
        }
        .chapter-item-section {
            width: fit-content;
            border-bottom: 3px solid #fffcf8;
            cursor: pointer;
            margin-left: 30px;
            margin-bottom: 10px;
            &::after {
                display: block;
                content: "";
                width: 0%;
                height: 3px;
                background-color: vars.$main-color;
                transition: width 0.3s;
            }
            &:hover::after {
                width: 100%;
            }
            &:hover {
                color: vars.$main-color;
            }
        }
    }
}
