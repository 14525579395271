@use "src/styles/vars";

.product-professor-wrapper {
    color: white;
    background-color: #6f8aa1;
    padding: 30px 0px;
    .product-professor-img-name-wrapper {
        padding: 0 15px;
        position: relative;
        .professor-image-wrapper {
            width: 75%;
            height: 250px;
            margin: auto;
            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
                margin: auto;
            }
        }

        .professor-name {
            text-align: center;
            color: white;
            padding: 10px 10px;
            text-transform: uppercase;
            background-color: #6f8aa1;
            font-size: 20px;
            font-weight: 600;

            div:not(:last-child) {
                margin-bottom: 5px;
            }
        }
    }

    .product-professor-description {
        padding: 0 15px;
        margin-top: 40px;
        text-align: justify;
        // text-indent: 30px;
    }

    .product-professor-button {
        width: 170px;
        text-transform: uppercase;
        background-color: #fff;
        color: rgb(70, 69, 69);
        padding: 10px 10px;
        margin: auto;
        margin-top: 30px;
    }

    .product-professor-button:hover {
        background-color: rgb(70, 69, 69);
        color: #fff;
        cursor: grab;
    }
}

@media (min-width: 1000px) {
    .product-professor-wrapper {
        width: 85%;
        // padding: 50px 120px;
        margin: auto;

        .product-professor-img-name-wrapper {
            width: 73% !important;
            margin: auto;
            .professor-image-wrapper {
                height: 400px;
                width: 100%;
            }

            .professor-name {
                font-size: 28px;
                position: initial;
                text-align: center;
                div {
                    padding-bottom: 10px;
                }
            }
        }

        .product-professor-description {
            border-top: white 2px solid;
            width: 70%;
            margin: auto;
        }
    }
}

@media (min-width: 1600px) {
    .product-professor-wrapper {
        width: 73% !important;
        .professor-image-wrapper {
            width: 50% !important;
            margin: auto;
        }
        .professor-name {
            width: 50%;
            margin: auto;
            div {
                text-align: center;
            }
        }
        .product-professor-description {
            width: 35%;
            margin: auto;
        }
    }
}
