@use "src/styles/vars";

.professeurs-catalogue-wrapper {
    padding: 0 50px;
    .professeur-catalogue-cards-wrapper {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        // margin: 0 auto;
        justify-content: flex-start;
    }

    .catalogue-professeurs-pagination-wrapper {
        background-color: white;
    }
}

@media (min-width: 1600px) {
    .professeurs-catalogue-wrapper {
        padding: 2% 10%;
    }
}
