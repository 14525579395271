@use "src/styles/vars";

.privacy-policy-wrapper {
    text-align: justify;
    background-color: #fffcf8;
    padding: 50px 300px;
    line-height: 20px;

    h1 {
        text-align: center;
        color: vars.$main-color;
        text-transform: uppercase;
        font-size: 25px;
        // padding-bottom: 50px
    }
    h2 {
        margin-top: 70px;
        color: vars.$main-color;
        text-transform: uppercase;
        position: static;
    }

    h3 {
        color: vars.$main-color;
        text-transform: uppercase;
    }
    p {
        text-indent: 30px;
    }
    table {
        text-align: left;
        border: 1px solid rgb(139, 139, 139);

        td {
            border: 1px solid rgb(139, 139, 139);
            width: 33%;
            padding-left: 10px;

            p {
                text-indent: 0px;
            }
        }
    }
}

@media (max-width: 1000px) {
    .privacy-policy-wrapper {
        padding: 0px;

        h1,
        h2,
        h3,
        h4,
        p,
        ul {
            padding: 0 50px;
        }
        h1 {
            padding-top: 50px;
        }
        p {
            strong {
                text-indent: 0;
                padding: 0;
            }
        }
        strong {
            padding-left: 50px;
            display: block;
        }

        table {
            p {
                padding: 0;
            }
            strong {
                padding: 0;
            }
        }
    }
}
