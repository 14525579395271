@use "src/styles/vars";

.select-filters-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    color: vars.$main-color;

    .select-filters-items {
        text-align: start;
        margin: 6px 0;
        padding-left: 5px;
        width: 48%;
        cursor: pointer;
        .select-filters-cross {
            margin: 0 9px;
        }
    }
}

.select-filters-wrapper-closed {
    display: none;
}

@media (min-width: 1000px) {
    .select-filters-wrapper {
        width: 85%;
        align-items: center;
        justify-content: center;

        .select-filters-items {
            text-align: start;
            margin: 6px 0;
            padding-left: 5px;
            width: 17%;
            .select-filters-cross {
                margin: 0 9px;
            }
        }
    }
}
