@use "src/styles/vars";

.variants-choice-cart-popup-wrapper {
    animation: fadeIn 0.5s;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 1;
    position: absolute;
    bottom: 0;
    height: 100%;
    overflow: auto;
    // &.open {
    //     animation: fadeIn 0.5s;
    // }
    // &.closed {
    //     display: none;

    // }

    form {
        button {
            cursor: pointer;
            border: none;
            background-color: vars.$main-color;
            color: white;
            padding: 10px 30px;
            font-size: 16px;
            text-align: center;
            text-decoration: none;
            font-family: vars.$font;
            margin: 30px auto;
            display: block;
        }
    }

    .variants-choice-cart-toclose {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        z-index: 2;
    }
    .variants-choice-cart-popup-choose {
        text-align: center;
        margin: 10px 0;
        padding: 10px 0;
        color: vars.$main-color;
        font-weight: 600;
        border-bottom: 1px solid lightgrey;
    }

    .variants-choices-cart-item {
        padding: 10px 10px;
        line-height: 20px;
        border-bottom: 1px solid lightgray;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
            color: gray;
            cursor: pointer;
        }
        &.select {
            font-weight: 600;
        }
    }
    .validate-button {
        color: white;
        background-color: vars.$main-color;
        border: 1px solid vars.$main-color;
        width: -moz-fit-content;
        width: fit-content;
        padding: 7px;
        cursor: pointer;

        &:hover {
            background-color: white;
            color: vars.$main-color;
            border: 1px solid vars.$main-color;
        }
    }

    .cart-add-button {
        background-color: #80b074;
        color: white;
        //text-transform: uppercase;
        width: fit-content;
        width: -moz-fit-content;
        padding: 7px;
        // margin: 20px auto;
        border: 1px solid #80b074;
    }
}

// @media (min-width:1000px) {
//     .variants-choice-popup-wrapper {
//         top: 37% !important;
//         left: 32% !important;
//         width: 28% !important;

//     }

// }

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
