@use "src/styles/vars";

.activatecourse-wrapper {
    min-height: 100vh;
    background-color: #fffcf8;
    padding: 50px;
    position: relative;

    .spinner-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fffcf891;
    }

    .text-description {
        margin: 50px auto;
        font-weight: 600;
    }

    .buttons-wrapper {
        display: flex;
        justify-content: center;
    }
    .button-wrapper {
        margin: auto;
        width: fit-content;
    }

    .button-link {
        width: fit-content;
        flex-wrap: wrap;
        font-size: 15px;
        cursor: pointer;
        padding: 10px;
        margin: 10px 10px;
        background-color: vars.$main-color;
        border: 2px solid vars.$main-color;
        color: white;
        text-transform: uppercase;

        &:hover {
            background-color: white;
            color: vars.$main-color;
        }
    }

    button {
        flex-wrap: wrap;
        font-size: 15px;
        cursor: pointer;
        padding: 10px;
        margin: 10px 10px;
        background-color: vars.$main-color;
        border: 2px solid vars.$main-color;
        color: white;
        text-transform: uppercase;

        &:hover {
            background-color: white;
            color: vars.$main-color;
        }
    }
}
