@use "src/styles/vars";

.user-account-wrapper {
    display: flex;

    .account-title {
        width: 100%;
        height: 100%;
        text-transform: uppercase;
        margin: 0 auto;
        padding-top: 9%;
        padding-bottom: 20%;
        padding-right: 18%;
        font-weight: 600;
        color: #d99606;
        background-color: #fffcf8;
    }
}
@media (max-width: 1000px) {
    .user-account-wrapper {
        flex-wrap: wrap;
    }
}
