.user-orders-wrapper {
    width: 80%;
    min-height: 100vh;

    .orders-null {
        margin: 3% auto;
        text-align: center;
    }
}

@media (max-width: 1000px) {
    .user-orders-wrapper {
        width: 100%;
    }
}
