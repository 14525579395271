@use "src/styles/vars";

.filtersItems-and-filterUnderItems-closed {
    display: none;
}

.filters-sort-items-wrapper {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px grey solid;
    .filters-sort-items-title {
        margin: 0 5%;
        width: 65%;
        text-align: left;
    }
    .filters-sort-items-title-chevron {
        transform: rotate(90deg);
        margin: 0 10%;
    }

    .filter-sort-items-number-choice-click {
        background-color: vars.$main-color;
        color: white;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        line-height: 25px;
    }
}

@media (min-width: 1000px) {
    .filtersItems-and-filterUnderItems {
        position: relative;
        cursor: pointer;
        animation: fadeIn 0.5s;
        width: 20%;
        .filters-sort-items-wrapper {
            border: 1px lightgrey solid;
            background-color: white;
            margin: 2px 3px;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
