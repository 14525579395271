.association-wrapper {
    padding: 20px 0;
    .association-title {
        text-transform: uppercase;
        margin: auto;
        font-weight: 600;
        font-size: 20px;
        padding-bottom: 5px;
        border-bottom: 2px solid black;
        width: fit-content;
        margin: 50px auto;
    }

    .caroussel-association-wrapper {
        &.flex {
            display: flex;
            justify-content: center;
        }
        .association-slider-card-wrapper {
            padding: 0 20px;
        }

        // .alice-carousel__stage-item {
        //     width: 20% !important;
        //     height: 80% !important;
        // }
        .recommendation-slider-card-wrapper {
            margin: auto;
        }
    }

    .alice-carousel__dots {
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 99% !important;

        .alice-carousel__dots-item {
            background-color: gray;
            width: 6px !important;
            height: 6px !important;
            border-radius: 0%;
            margin-right: 7px !important;

            &.__active {
                background-color: lightgray !important;
            }
        }
    }

    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
        display: none;
    }
}

@media (min-width: 1000px) {
    .association-slider-card-wrapper {
        max-width: 400px;
    }
    .association-wrapper {
        width: 100%;

        .alice-carousel__prev-btn,
        .alice-carousel__next-btn {
            display: block;
        }
    }
}

@media (min-width: 1600px) {
    .association-wrapper {
        width: 73% !important;
        margin: auto;
    }
}
