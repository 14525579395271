@use "src/styles/vars";

.contact-wrapper {
    padding: 7%;
    min-height: 90vh;
    text-align: left;
    background-color: #fffcf8;

    div {
        margin-top: 3%;
        a {
            text-decoration: underline;
            color: vars.$main-color;
        }
    }
    img {
        width: 6rem;
        margin: 2rem auto;
        display: block;
    }

    .contact-hour {
        margin: 3em 0;
        width: fit-content;
        text-align: start;

        li {
            margin: 1em 0;

            span {
                font-weight: 600;
            }
        }
    }
    .contact-mailto {
        font-size: 1.5em;
        font-weight: 600;
    }

    .contact-seo-wrapper {
        text-align: justify;
    }
}

@media (max-width: 600px) {
    .contact-wrapper {
        // padding: 0;

        img {
            width: 5rem;
        }
    }
}
