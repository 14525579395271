@use "src/styles/vars";

.personal-infos-wrapper {
    background-color: #fffcf8;
    width: 76%;
    min-height: 100vh;
    .spinner-personnalinfos-spinner {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: #ffffff80;
        z-index: 2;
    }

    form {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        width: 100%;
        .personal-infos-wrapper-side {
            padding: 0 3.5%;
            width: 41%;
            .input-wrapper {
                padding: 20px 20px;
                display: flex;
                flex-direction: column;
                .personal-label {
                    text-align: left;
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 7px;
                    padding-left: 7px;
                }
                .personal-input {
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    font-family: vars.$font !important;
                    font-size: 16px;
                    padding: 7px 7px;
                    background-color: #fffcf8;
                }

                select {
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    border-bottom-width: 1px;
                    font-family: vars.$font !important;
                    font-size: 16px;
                    padding: 7px 7px;
                    background-color: #fffcf8;
                    option {
                        padding: 2px;
                    }
                }
            }
            .input-information {
                text-align: left;
                color: gray;
                padding: 0 20px;
                margin-bottom: 20px;
                &.error {
                    text-align: left;
                    font-weight: 300 !important;
                    color: #d73307 !important;
                }
            }
        }

        .password-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            div {
                padding: 20px 20px;

                span {
                    display: inline-block;
                }

                span:first-child {
                    font-weight: 600;
                    margin-right: 10px;
                }

                .button-password {
                    cursor: pointer;
                    border-bottom: 1px solid black;
                }
            }
        }

        .submit-button-wrapper {
            margin: 30px 30px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            button {
                cursor: pointer;
                border: none;
                background-color: vars.$main-color;
                color: white;
                padding: 10px 30px;
                font-size: 16px;
                text-align: center;
                text-decoration: none;
                font-family: vars.$font;
            }

            .confirmation-message {
                span {
                    animation: fadeIn 0.6s;
                }

                font-weight: 600;
                width: 60%;
                color: #80b074;
            }
        }
    }
}

@media (max-width: 1000px) {
    .personal-infos-wrapper {
        width: 100%;

        form {
            width: 100%;

            select {
                -webkit-appearance: none;
            }

            input[type="date"] {
                -webkit-appearance: none;
            }

            .personal-infos-wrapper-side {
                width: 100% !important;

                button {
                    margin: auto;
                }
                .confirmation-message {
                    width: 100% !important;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
