@use "src/styles/vars";

.accordions-products-wrapper {
    padding-bottom: 10px;
    a {
        text-decoration: underline;
        color: inherit;
    }

    .accordions-products-title-wrappper {
        cursor: pointer !important;
        scroll-margin-block-start: 10rem !important;
        background-color: #f9f5f0 !important;
        font-size: 17px !important;
        padding: 15px 40px !important;
        font-weight: 600;
        text-transform: uppercase !important;
        text-align: left !important;
        position: relative !important;
        .accordions-products-title {
            display: inline-block;
            unicode-bidi: isolate;
            text-transform: uppercase;
            padding: 0.4rem;
            position: relative;
            font-size: 16px !important;
            z-index: 0;
        }
        .accordions-products-chevron-closed {
            animation: chevronClosed 0.3s;
            display: inline-block;
            transform: rotate(270deg);
            position: absolute;
            right: 50px;
        }

        .accordions-products-chevron-open {
            display: inline-block;
            animation: chevronOpen 0.3s;
            transform: rotate(90deg);
            position: absolute;
            right: 50px;
            top: 5px;
        }
    }

    .accordions-products-informations-wrapper {
        animation: fadeIn 0.8s;
        padding: 0 40px;
        text-align: left;
        background-color: #fffcf8;
        line-height: 20px;

        .accordion-under-title {
            text-transform: uppercase;
            font-weight: 600;
            margin-bottom: 10px;
            padding-top: 15px;
            font-size: 15px;
        }
    }

    .closed {
        display: none;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes chevronOpen {
    0% {
        transform: rotate(270deg);
    }
    100% {
        transform: rotate(90deg);
    }
}

@keyframes chevronClosed {
    0% {
        transform: rotate(90deg);
    }
    100% {
        transform: rotate(270deg);
    }
}

@media (min-width: 1000px) {
    .accordions-products-wrapper {
        margin: 10px auto;
        width: 85% !important;
        background-color: #fffcf8;

        .accordions-products-title-wrappper {
            text-align: center !important;
            background-color: #fffcf8 !important  ;
            color: vars.$main-color !important;
            // padding: 20px 40px;

            .accordions-products-title {
                padding: 0rem !important;
            }
        }

        .accordions-products-informations-wrapper {
            padding: 0 70px;

            .accordion-materiel-prerequis-wrapper {
                display: flex;
                justify-content: space-between;
                padding-top: 20px;
            }
        }
    }
}

@media (min-width: 1600px) {
    .accordions-products-wrapper {
        width: 73% !important;
    }
}
