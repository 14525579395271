@use "src/styles/vars";

.under-navbar-taxons {
    height: 100%;
    width: 61%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    section {
        margin: 0 2.5%;
        height: 100%;

        .taxons-items-button-active {
            animation: fadeIn 0.5s;
            width: 300px;
            position: absolute;
            bottom: -720%;
            left: 33%;
            font-size: 15px;
        }
        .taxons-items-button {
            display: none;
        }
    }
}

.under-navbar-taxons-scroll {
    position: relative;
    width: 61%;
    display: flex;
    justify-content: center;
    font-size: 13px;
    align-items: center;
    height: 100%;

    section {
        margin: 0 2.5%;
        height: 100%;

        .taxons-items-button-active {
            animation: fadeIn 0.5s;
            width: 300px;
            position: absolute;
            bottom: -720%;
            left: 33%;
        }
        .taxons-items-button {
            display: none;
        }
    }
    section:last-child {
        .taxons-items {
            color: #d73307;
        }
    }
}

.taxons-items {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    .taxons-items-navlinks {
        text-decoration: none;
        color: inherit;
    }
}
.taxons-items:hover {
    color: vars.$main-color;
    cursor: pointer;
}

.taxons-items-wrapper-active {
    animation: fadeIn 0.5s;
    position: absolute;
    display: flex;
    top: 100%;
    left: 0;
    width: 100%;
    height: 374px;
    // padding: 3% 0 ;
    padding-bottom: 10%;
    background-color: #ffff;
}

.taxons-items:last-child {
    color: red;
}

.taxons-items-wrapper {
    display: none;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

// @media (max-width: 1000px) {
//     .under-navbar-taxons {
//         display: none;
//     }
//     .under-navbar-taxons-scroll {
//         display: none;
//     }
// }
