@use "src/styles/vars";

.deconnect-popin-wrapper {
    background-color: #f9f5f083;
    height: 100%;
    width: 100%;
    z-index: 5;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: fixed;
    .wrapper {
        background-color: #f9f5f0;
        height: 20%;
        width: 30%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;

        div {
            font-weight: 600;
            margin: 20px;
        }
        a {
            cursor: pointer;
            border: none;
            background-color: vars.$main-color;
            color: white;
            padding: 10px 30px;
            font-size: 16px;
            text-align: center;
            text-decoration: none;
            font-family: vars.$font;
            margin: auto;
        }
    }
}

@media (max-width: 600px) {
    .deconnect-popin-wrapper {
        .wrapper {
            padding: 0 20px;
            width: 80%;
        }
    }
}

@media (min-width: 600px) and (max-width: 1000px) {
    .deconnect-popin-wrapper {
        .wrapper {
            padding: 0 20px;
            width: 80%;
        }
    }
}
