@use "src/styles/vars";

video {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.videoCard-wrapper {
    text-transform: uppercase;
    position: relative;
    font-weight: 700;
}

.videoCard-link {
    position: absolute;
    min-width: 10%;
    top: 70%;
    left: 50%;
    /* subtract half in height and width */
    transform: translate(-50%, -50%);
    font-size: 0.8rem;
    background-color: #fff;
    padding: vars.$normal-font-size;
}

.videoCard-link:hover {
    background-color: rgb(70, 69, 69);
    color: #fff;
    cursor: pointer;
}

.videoCard-title {
    color: white;
    width: 100%;
    padding: 0% !important;
    position: absolute;
    top: 45%;
    font-size: vars.$title-font-size;
}

@media (max-width: 600px) {
    .videoCard-wrapper {
        .videoCard-link {
            top: 80%;
        }
    }
}
