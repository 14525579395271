@use "src/styles/vars";

.header-wrapper {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 30px;
    height: 5rem;
    justify-content: space-between;
    box-sizing: border-box;
    .header-logo {
        box-sizing: border-box;
        width: 12rem;
        margin: auto;
        box-sizing: border-box;
        img {
            object-fit: contain;
            width: 100%;
        }
    }
    .header-progression {
        box-sizing: border-box;
        margin: 9px 0 29px;
        display: table;
        width: 80%;
        font-size: 0;
        text-align: center;
    }

    .header-progression > div:first-child:before,
    .header-progression > div:last-child:after {
        box-sizing: border-box;
        background-color: white !important;
    }
    .header-progression > div:before,
    .header-progression > div:after {
        box-sizing: border-box;
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 50%;
        height: 3px;
        background-color: vars.$main-color;
    }
    .progression-item > span {
        box-sizing: border-box;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        padding: 1px 0 0;
        margin: 0 -18px;
        width: 36px;
        font: 700 14px/32px vars.$font;
        background: #fff;
        border-radius: 99em;
        border: 2px solid vars.$main-color;
        color: vars.$main-color;
        background-color: white;
    }

    .progression-item.active > span {
        color: white;
        background-color: vars.$main-color;
    }

    .progression-item.active:after {
        background-color: lightgrey;
    }

    .progression-item.active ~ .progression-item:after,
    .progression-item.active ~ .progression-item:before {
        background-color: lightgray;
    }
    .progression-item.active ~ .progression-item > span {
        color: lightgrey;
        // background-color: lightgray;
        border: 2px solid lightgray;
        &:before {
            color: lightgray;
        }
    }
    .progression-item {
        box-sizing: border-box;
        display: table-cell;
        text-transform: uppercase;
    }
    .progression-item > span::before {
        color: vars.$main-color;
        box-sizing: border-box;
        content: attr(title);
        position: absolute;
        font-size: 12px;
        width: 72px;
        top: 120%;
        left: -64%;
        // right: -500%;
    }
}

@media (max-width: 600px) {
    .progression-item > span::before {
        content: "" !important;
    }
    .header-progression {
        width: 100% !important;
    }
}
