@use "src/styles/vars";

// .lesson-card-selection-link {

//     color: inherit;
.lesson-card-selection-wrapper.small {
    height: 450px;

}
.lesson-card-selection-wrapper {
    position: relative;
    animation: fadeIn 0.8s;
    width: 100%;
    height: 600px;
    text-align: left;
    // margin: 0 2%;

    .spinner-card-wrapper {
        width: 100%;
        height: 97%;
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.493);
    }

    .lesson-card-selection-img-wrapper.small {
     height: 64% !important;
    }

    .lesson-card-selection-img-wrapper {
        width: 100%;
        height: 55%;
        position: relative;

        .no-stocks {
            position: absolute;
            background-color: rgba(0, 0, 0, 0.623);
            height: 100%;
            width: 100%;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            line-height: 25px;
            span {
                color: #f9f5f0;
                padding: 0 10px;
                text-align: center;
                display: block;
            }
        }

        .lesson-card-selection-img {
            object-fit: cover !important;
            width: 100%;
            height: 100%;
        }

        .lesson-card-selection-solde {
            position: absolute;
            top: 2%;
            right: 2%;
            background-color: #d73307;
            color: #fff;
            font-weight: 600;
            padding: 3%;
        }
    }
    .lesson-card-selection-text-wrapper {
        width: 100%;
        display: flex;
        background-color: #fffcf8;

        .lesson-card-selection-pack {
            width: 10%;
            height: 100%;
            text-transform: uppercase;
            transform: (rotate(180deg));
            writing-mode: vertical-lr;
            display: inline-block;
            background-color: #061f4b;
            color: #fff;
            display: inline;
            text-align: center;
            padding-left: 10px;
            letter-spacing: 2px;

            .lesson-card-selection-pack-text {
                font-weight: 600;
                padding: 10px;
            }
        }
        .lesson-card-selection-text {
            position: relative;
            width: 90%;
            height: 87%;
            display: inline;
            padding: 4% 6%;

            .lesson-card-selection-new-active {
                position: absolute;
                text-transform: uppercase;
                color: #80b074;
                font-weight: 700;
                font-size: vars.$normal-font-size;
            }

            .lesson-card-selection-new {
                display: none;
            }

            .lesson-card-selection-title {
                font-weight: 700;
                font-size: 18px;
                margin-top: 7%;
                &:hover {
                    color: rgb(168, 159, 159);
                }
            }

            .lesson-card-selection-professor {
                font-size: 16px;
            }

            .lesson-card-selection-buy-wrapper {
                display: flex;
                justify-content: space-between;
                position: absolute;
                bottom: 15px;
                margin-bottom: 0% !important;
                width: 90%;

                .lesson-card-selection-price {
                    width: 65%;
                }
                .addCardAndWishlist-selection-icon-wrapper {
                    width: 35%;
                }
            }
        }
        .lesson-card-selection-text > * {
            margin-bottom: 5%;
        }
    }

    .lesson-card-selection-text-wrapper.normal {
        height: 42%;
    }
    .lesson-card-selection-text-wrapper.small {
        height: 35%;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 1000px) {
    .lesson-card-selection-wrapper {
        max-width: 500px;
        margin: auto;
    }
}
