.banner-wrapper {
    background-color: black;
    position: sticky;
    top: 0px;
    z-index: 3;
    font-size: 20px;
    min-height: 39px;

    .banner-text {
        color: white;

        a {
            width: 100%;
            display: block;
            padding: 10px 0;

            span {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .banner-wrapper {
        position: relative;
    }
}
