.professeur-profil-publique-product-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 2% 1%;
    margin-top: 1%;
    background-color: #f9f5f0;

    .professeur-profil-publique-product {
        width: 23%;
        margin: 1% 1%;
    }
}

#topAnchor {
    display: block;
    position: relative;
    top: -150px; /* Pour compenser le positionnement de l'ancre */
    visibility: hidden;
}

@media (max-width: 600px) {
    .professeur-profil-publique-product {
        width: 80% !important;
        margin: auto !important;
    }
}

@media (min-width: 601px) and (max-width: 1000px) {
    .professeur-profil-publique-product {
        width: 45% !important;
        margin: auto !important;
    }
}

@media (min-width: 1600px) {
    .professeur-profil-publique-product-wrapper {
        padding: 2% 10%;
    }
}
