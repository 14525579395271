@use "src/styles/vars";

.adress-card-wrapper {
    padding: 32px 23px;
    .adress-card-title {
        text-transform: uppercase;
        padding: 5px 0;
        margin-bottom: 7px;
        color: vars.$main-color;
        font-weight: 600;
        border-bottom: 1px solid vars.$main-color;
    }

    .adress-card-information {
        margin-top: 5px;
        .last-name {
            text-transform: uppercase;
        }
    }

    .adress-card-button-wrapper {
        margin-top: 30px;

        span {
            margin-right: 15px;
            font-weight: 600;
            // color: white;
            // background-color: vars.$main-color;
            border-bottom: 1px solid black;
            padding: 3px 0;
            cursor: pointer;
        }
    }
}

@media (max-width: 600px) {
    .addresses-wrapper {
        .addresses-wrapper-default {
            .adress-card-wrapper {
                width: 90%;
            }
        }
    }
}
