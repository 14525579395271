@use "src/styles/vars";

.navlogo-wrapper {
    height: 10%;
    background-color: #f9f5f0;
}

.navlogo-items {
    cursor: pointer;
    display: inline-block;
    margin: 0% 3%;
    padding-top: 2rem;
    font-size: vars.$normal-font-size;
    text-transform: uppercase;
    color: vars.$main-color;
    font-weight: 700;
    border-bottom: #f9f5f0 solid 3px;
    &::after {
        display: block;
        content: "";
        width: 0%;
        height: 2px;
        background-color: black;
        transition: width 0.3s;
    }
    &:hover::after {
        width: 100%;
    }
}

.pictogram {
    height: 7vh;
    margin-bottom: 1.2rem;
}

.navlogo-title-wrapper {
    color: #000;
    font-size: 13px;
}

.navlogo-title {
    margin-bottom: 4px;
}
.navlogo-title-actif:hover {
}

@media (max-width: 1000px) {
    // .navlogo-wrapper {
    //     padding-bottom: 2rem 0;
    // }

    .navlogo-items {
        margin: 0%;
        width: 50%;
    }
}
