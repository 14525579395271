.mediamenu-wrapper {
    display: flex;
    padding: 0px 10px;
    .mediamenu-item {
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25%;
        text-align: start;
        border-top: 1px solid black;
        border-left: 1px solid black;
        border-right: 1px solid black;

        .mediamenu-img {
            width: 40px;
        }
        .media-menu-title {
            margin-left: 7px;
            // display: none;
            text-transform: uppercase;
            max-width: 50%;

            &.select {
                display: block;
            }
        }
        &.active {
            width: 50%;
            background-color: #f9f5f0;
            border-top: 1px solid #f9f5f0;
            border-left: 1px solid #f9f5f0;
            border-right: 1px solid #f9f5f0;
            .media-menu-title {
                margin-left: 7px;
                display: block;
                font-weight: 600;
                font-size: 14px;
            }
        }
    }
}

@media (min-width: 600px) {
    .mediamenu-wrapper {
        width: 90%;
        margin: auto;
    }
}

@media (max-width: 1000px) {
    .media-menu-title {
        display: none;
    }
}
