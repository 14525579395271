@use "src/styles/vars";

.reinssurance-and-title-wrapper {
    background-color: #fffcf8;
    padding: 5rem 0 ;
    .reinssurance-title {
       
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 3rem;
       

    }
    .reinssurance-wrapper {
        height: 10%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
    
    
        .reinssurance-title {
            text-align: center;
        } 
        h4 {
            margin: 0;
            color: vars.$main-color;
            font-size: 19px;
            margin-bottom: 10px;
        }
    
    
    }
    
    .reinssurance-items {
        display: flex;
        width: 40%;
        padding-top: 2rem;
        font-size: 15px;
        align-items: flex-start;
        margin-left: 2rem;

        .text-wrapper {
            width:85% ;
            text-align: justify;
        }
    
    }

    .catchPhrase {
        text-align: justify;
    }
    
    .pictogram {
        height: 7vh;
        margin-bottom: 0;
        margin-right: 1rem;
    }
    
    .catchPhrase.colorBlack {
        color: #000;
    }
    
    .catchPhrase-start {
        margin-bottom: 4px;
    }
}

@media (max-width: 600px) {
   .reinssurance-and-title-wrapper {
    display: none;
   }
}

