//------------- Main Font-size

$normal-font-size: 15px;

$paragraph-header: 20px;

$title-font-size: 38px;

$max-size-font: 42px;

//-----------Font-family

$font: "Josefin Sans", sans-serif;
$numFont: "arial", sans-serif;

//------------Letter spacing

$letter-spacing: -1px;

// Theme-color

$main-color: #d99606;

$green: #80b074;

// Opacity on a:hover

$opacity: 0.8;
