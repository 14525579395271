.summary-wrapper {
    padding: 15px 0;
    text-align: justify;
}

@media (min-width: 1000px) {
    .summary-wrapper {
        padding: 30px 0;
    }
}
