@use "src/styles/vars";

.networks-input-wrapper {
    padding: 10px 20px;
    display: flex;
    position: relative;
    .networks-input {
        border-top: none;
        border-left: none;
        border-right: none;
        font-family: vars.$font !important;
        font-size: 16px;
        padding: 7px 7px;
        margin-left: 15px;
        background-color: #fffcf8;
        width: 60%;
    }
    .networks-image {
        width: 30px;
    }
}

@media (max-width: 1000px) {
    .networks-input-wrapper {
        input {
            width: 100% !important;
        }
    }
}
