.order-wrapper {
    display: flex;
    flex-direction: row-reverse;
    padding: 25px 50px;
    position: relative;
    background-color: #fffcf8;
    .spinner-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        background-color: #ffffff66;
        height: 100%;
    }
}

@media (max-width: 1000px) {
    .order-wrapper {
        display: flex;
        flex-direction: column;
        padding: 10px;
    }
}
