@use "src/styles/vars";

.notfound-wrapper {
    height: 90vh;
    padding: 7% 0;
    background-color: #fffcf8;
    .bloc-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 7%;
        }
        img.reverse {
            transform: scaleX(-1);
        }
        .text-wrapper {
            padding: 20px;
            .not-found-title {
                text-transform: uppercase;
                color: vars.$main-color;
                font-weight: 600;
                font-size: 25px;
                margin-bottom: 30px;
            }
            .not-found {
                div {
                    margin-bottom: 10px;
                    font-size: 20px;
                }
                a {
                    border-bottom: 1px solid vars.$main-color;
                    color: vars.$main-color;
                }
            }
        }
    }
}
