@use "src/styles/vars";


.newsletterform-element-wrapper {
    // width: 100%;
    padding:20px;
    background-color: #f9f5f0;

    .newsletterform-background-wrapper {

        background-image: url('https://media.artesane.com/media/cache/artesane_original/e1/ba/ae82026e7c082a68a84ff42d1d02.jpeg.webp'); /* Lien vers votre image */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
        background-size: cover; /* L'image couvrira tout l'écran */
        background-position: center center; /* Centre l'image horizontalement et verticalement */
        background-attachment: scroll; /* L'image reste fixe lors du défilement */
        // padding: 3rem 7rem;

        .newsletterform-wrapper {
            background-color: #fffcf8;
            padding: 20px;
            // display: flex;
            justify-content: space-between;

            .newsletterform-left-side {
                text-align: left;
                width: 47%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .uppercase {
                    text-transform: uppercase;
                    font-size: 25px;
                    font-weight: 800;
                    margin-bottom: 20px;

                }

                .details {
                    color: grey;
                    font-size: 13px;
                    a {
                        text-decoration: underline;
                    }
                }
            }

            form {
                width: 100%;
                margin-top: 20px;

                ul {
                    list-style-type: none;


                    li {
                        text-align: left;
                    }
                }

                .newsletter-footer-input{
                    font-size: vars.$normal-font-size;
                    background-color: #f9f5f0;

                    font-weight: 700;
                    border: none;
                    text-align: center;
                    // padding-left: 2%;
                    height: 40px;
                    width: 100%;

                    &::placeholder {
                        color: rgb(196, 188, 188) !important;
                        font-family: vars.$font !important;
                    }
                }

                button {
                    background-color: #d99606;
                    border: none;
                    color: #fff;
                    cursor: pointer;
                    font-size: 15px;
                    height: 40px;
                    text-transform: uppercase;
                    width: 100%;
                }


            }

            .newsletterform-left-side {

            }

        }

    }

}


@media (min-width: 1000px) {

    .newsletterform-element-wrapper {
        padding:2rem 5rem;

        .newsletterform-background-wrapper {
            padding: 3rem 7rem;
            .newsletterform-wrapper{
                padding: 3rem;
                display: flex;

                form {
                    width: 50%; 
                    ul {
                        display: flex;
                        justify-content: space-between;
                    }
                }


            }
        }
    }
    
}
