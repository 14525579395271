.wishlist-wrapper {
    background-color: #f9f5f0;
    padding: 20px 40px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
    .wishlist-items-wrapper {
        width: 28%;
        margin: 1% 2.5% !important;
    }
    .wishlist-null {
        margin: 3% auto;
        text-align: center;
    }
}

@media (max-width: 600px) {
    .wishlist-wrapper {
        //    padding: 20px 10%;
        .wishlist-items-wrapper {
            width: 100%;
            // margin: 1% 2.5% !important;
        }
    }
}

@media (min-width: 601px) and (max-width: 1000px) {
    .wishlist-wrapper {
        //    padding: 20px 10%;
        .wishlist-items-wrapper {
            width: 45%;
            // margin: 1% 2.5% !important;
        }
    }
}
@media (min-width: 1600px) {
    .wishlist-wrapper {
        //    padding: 20px 10%;
        .wishlist-items-wrapper {
            width: 20%;
            margin: 1% 2.5% !important;
        }
    }
}
