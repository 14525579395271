.order-summary-wrapper {
    padding-left: 30px;
    margin-top: 30px;

    .order-summary-cards-wrapper {
        padding: 20px 0;
        .order-summary-card {
            display: flex;
            align-items: center;
            text-align: left;
            margin-top: 10px;
            font-weight: 600;
            img {
                width: 7%;
                margin-right: 20px;
            }
            span {
                width: 25%;
    
            }
            span.name-product{
                width: 50%;
            }
        }
    }


}   



@media (max-width: 1000px) {

    .order-summary-wrapper {

        margin-top: 0px;
        padding:  0 30px !important;

        .order-summary-cards-wrapper {
            font-size: 13px;

            img {
                width: 20% !important;
            }

            span {
                width: 40% !important;
            }
        }
    }
   
}