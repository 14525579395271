@use "src/styles/vars";

.livraison-et-retours-wrapper {
    text-align: justify;
    background-color: #fffcf8;
    padding: 50px 300px;
    line-height: 20px;

    h1 {
        // text-align: center;
        color: vars.$main-color;
        text-transform: uppercase;
        font-size: 25px;
        padding-bottom: 50px;
    }
    h2 {
        margin-top: 70px;
        position: static;
    }
}

@media (max-width: 1000px) {
    .livraison-et-retours-wrapper {
        padding: 50px 50px;
    }
}
