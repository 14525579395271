.reduction-wrapper {
    text-transform: uppercase;
    background-color: #d73307;
    color: white;
    width: fit-content;
    padding: 6px 5px;
    font-size: 14px;
    position: absolute;
    top: 10px;
    left: 20px;
}

@media (min-width: 1000px) {
    .reduction-wrapper {
        position: static;
        width: 9rem;
        display: flex;
        align-items: center;
    }
}
