@use "src/styles/vars";

.mediaproduct-card-wrapper {
    // max-height: 360px;
    width: 90%;
    margin: 40px auto;
    a {
        height: 100%;
        .mediaproduct-card-image-wrapper {
            width: 100%;
            height: 200px;
            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
        .mediaproduct-card-infos {
            padding: 15px 0;
            background-color: #fffcf8;
            width: 100%;
            height: 131px;
            position: relative;
            .mediaproduct-card-title {
                font-weight: 600;
                font-size: 18px;
                margin-bottom: 7px !important;
                width: 95%;
                margin: auto;
            }
            .mediaproduct-card-teacher {
                margin-bottom: 20px;
            }
            .mediaproduct-card-button-wrapper {
                width: 100%;
                position: absolute;
                bottom: 15px;
                .mediaproduct-card-button {
                    border: 1px solid vars.$main-color;
                    width: fit-content;
                    margin: 10px auto;
                    padding: 12px 10px;
                    font-size: 15px;
                    color: vars.$main-color;
                    text-transform: uppercase;
                    &:hover {
                        color: #fffcf8 !important;
                        border: 1px solid vars.$main-color;
                        background-color: vars.$main-color;
                    }
                }
            }
        }
    }
}

@media (min-width: 600px) and (max-width: 999px) {
    .mediaproduct-card-wrapper {
        width: 46.5%;
        margin: 26px 10px;
        .mediaproduct-card-image-wrapper {
            width: 100%;
            height: 280px !important;
        }
    }
}

@media (min-width: 1000px) {
    .mediaproduct-card-wrapper {
        width: 30%;
        margin: 26px 16px;
    }
}

@media (min-width: 1600px) {
    .mediaproduct-card-image-wrapper {
        width: 100%;
        height: 400px !important;
    }
}
