@use "src/styles/vars";

.payment-accordion-wrapper {
    .radio-input {
        -webkit-appearance: none;
    }

    .payment-item-wrapper {
        margin: 0 0 16px;
        padding: 0;
        background: #f9f5f0;
        .legals-info {
            font-weight : 400;
            margin: 0;
            font-size: 13px;
            width: 60%;
            text-align: justify;
            a{
                color: vars.$main-color;
            }
        }
    }
    .payment-item{
        width: 100%;
        display: flex;
        padding: 0;
        margin: auto;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;
        img {
            margin: 16px 24px 16px auto;
            height: 25px;
            width: auto;
            opacity: 1;
            transition: opacity .3s ease;
        }
    }

    .payment-item-content:before {
        content: "";
        display: block;
        position: relative;
        top: -24px;
        width: 100%;
        height: 1px;
        background: #d5d5d5;
    }
    .payment-item-content{
        padding: 24px;
        margin: 0px !important;

        button.payment-button  {
            margin: 0px;
            font-size: 14px !important;
            width: 35% ;
        }
        .payment-item-content-info{
            margin: 0px;
            display: flex;
            justify-content: space-between;
        }
    }
    .payment-item:before {
        box-sizing: border-box;
        content: "";
        position: static;
        margin: 24px;
        top: -4px;
        left: 0;
        width: 24px;
        min-width: 24px;
        height: 24px;
        border: 1px solid #d5d5d5;
        border-radius: 12px;
        transition: all .3s ease;
    }
    .radio-input:checked+label.payment-item:before {
        background-color: #fff;
        border: 8px solid vars.$main-color;
    }

}

@media (max-width: 800px) { 

    .payment-item {
        img {
            height: 20px!important;
        }
    }
    .payment-item-content-info {
        display: block!important;
        text-align: center;
        .legals-info {
            width: 100% !important;
            margin-bottom: 20px !important;
        }

        button.payment-button {
            width: 100% !important;
            
        }
    }
}
