@use "src/styles/vars";

.home-faq-wrapper {
    background-color: #f9f5f0;
    padding: 3% 15%;
    font-size: 16px;
    position: relative;
    text-align: start;

    a {
        text-decoration: underline !important;
        color: vars.$main-color !important;
    }
    .home-faq-title {
        font-weight: 600;
        margin-bottom: 3%;
        font-size: 30px;
        letter-spacing: 3px;
        color: vars.$main-color;
        text-align: center;
    }

    .home-faq-button-wrapper {
        width: 15%;
        margin: auto;
        margin-top: 3%;
    }
    .css-15v22id-MuiAccordionDetails-root {
        padding: 0px 5% 18px 5%;
        text-align: justify;
    }
}

@media (max-width: 600px) {
    .home-faq-wrapper {
        padding: 10% 0%;
        text-align: justify;

        a {
            text-decoration: underline !important;
            color: vars.$main-color !important;
        }

        .home-faq-title {
            padding-bottom: 6%;
            margin: 0;
        }
        .home-faq-button-wrapper {
            width: 45%;
            padding-top: 7%;
            padding-bottom: 7%;
            margin-top: 0;
        }
    }
}

@media (min-width: 1600px) {
    .home-faq-wrapper {
        padding: 3% 24%;
        text-align: justify;
    }
}
