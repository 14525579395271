@import "react-alice-carousel/lib/scss/alice-carousel.scss";

.products-slider-wrapper {
    position: relative;
    height: 380px;
    background-color: #f9f5f0;
    .video-filter-drag-top {
        width: 100%;
        height: 142px;
        position: absolute;
    }
    .video-filter-drag-bottom {
        width: 100%;
        height: 142px;
        position: absolute;
        bottom: 0;
    }

    .products-slider-image-wrapper {
        width: 100%;
        height: 350px;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    .product-slider-video-wrapper {
        height: 350px;
        width: 100%;

        .react-player__preview {
            background-size: contain !important;
        }
    }

    .alice-carousel__dots {
        position: absolute;
        bottom: -30px;
        left: 0;
        width: 99% !important;

        .alice-carousel__dots-item {
            background-color: gray;
            width: 6px !important;
            height: 6px !important;
            border-radius: 0%;
            margin-right: 7px !important;

            &.__active {
                background-color: lightgray !important;
            }
        }
    }

    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
        display: none;
    }
}

@media (min-width: 600px) and (max-width: 1000px) {
    img {
        object-fit: contain !important;
    }
}
@media (min-width: 1000px) {
    .products-slider-wrapper {
        // width: 50%;
        // transform: rotate(90deg);
        position: relative;
        .product-slider-miniature-wrapper {
            //
            // transform: rotate(90deg) translateX(111px);
            // position: absolute;
            // right: -72px;

            .products-slider-image-wrapper {
                // padding: 0 20px;
                // width: 50%;
                // height: 20%;

                img {
                    // transform: rotate(270deg);
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }
        }
        .alice-carousel__prev-btn,
        .alice-carousel__next-btn {
            display: block;
        }

        .alice-carousel__dots {
            display: none;
        }
    }
}
