@use "src/styles/vars";

.card-item {
    width: 27.5%;
    color: #fff;
    position: relative;
}

.card-item img {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
    position: relative;
}
.card-item div {
    z-index: 1;
    text-transform: uppercase;
    padding: 0.4rem;
    position: absolute;
}

.card-item h1,
h2 {
    z-index: 1;
    text-transform: uppercase;
    padding: 0.4rem;
    position: absolute;
}

.title {
    display: block;
    font-weight: 700;
    font-size: vars.$title-font-size;
    top: 36%;
    width: 100%;
    padding: 0% !important;
}

.title:hover {
    animation: zoomTitle 0.8s;
}

@keyframes zoomTitle {
    from {
        font-size: vars.$title-font-size;
    }
    to {
        font-size: vars.$max-size-font;
    }
}

.nav-link-texte {
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6%;
    width: 60%;
    top: 71.5%;
    left: 50%;
    /* subtract half in height and width */
    transform: translate(-50%, -50%);
    font-size: vars.$normal-font-size;
    background: white;
    color: black;
    margin: auto;
}
.nav-link:visited {
    color: #fff;
}

.nav-link-texte:hover {
    background-color: #fff;
    color: vars.$main-color !important;
}

.card-item span {
    display: block;
}

@media (max-width: 600px) {
    .card-item {
        width: 100%;
        height: 100%;

        .title {
            top: 24%;
            font-size: 30px;
        }

        img {
            width: 100%;
            height: 18vh;
        }

        .nav-link-texte {
            top: 50%;
            height: 2.3rem;
            font-size: 13px;
        }
    }
}

@media (min-width: 601px)  and (max-width : 1000px) {
 .card-item {
    width: 50%;
 }
}
