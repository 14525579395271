@use "src/styles/vars";

.lesson-card-wrapper {
    width: 100%;
    height: 60vh;
    position: relative;
    .lesson-card-link-only {
        height: 100%;
        width: 100%;
    }
    .lesson-card-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .lesson-card-text-wrapper {
        width: 16%;
        // height: 50%;
        position: absolute;
        top: 8%;
        left: 10%;
        background-color: #ffffffbf;
        padding: 2.5% 3%;

        .lesson-card-title,
        .lesson-card-level {
            font-size: vars.$paragraph-header;
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 2%;
        }

        .lesson-card-description {
            padding-top: 1rem;
            text-align: justify;
            margin: 6% 0%;
        }

        .lesson-card-button-wrapper {
            margin: auto;
        }

        .lesson-card-link {
            width: fit-content;
        }
    }
}

@media (max-width: 600px) {
    // .__active {

    //     .lesson-card-text-wrapper {
    //         animation: fadeIn 3s;
    //     }
    // }

    .lesson-card-text-wrapper {
        width: 60% !important;
        min-height: fit-content !important;
        max-height: 84%;
    }
}

@media (min-width: 600px) and (max-width: 1000px) {
    .lesson-card-text-wrapper {
        width: 40% !important;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
