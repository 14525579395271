.mediaproduct-item-wrapper {
    padding: 10px 20px;
    .mediaproduct-subject {
        text-transform: uppercase;
        text-align: left;
        font-weight: 600;
        border-bottom: 3px solid black;
        padding: 5px 0;
        width: 96%;
        margin: auto;
    }
    .media-product-items {
        min-height: 50px;
        .no-result {
            margin-top: 20px;
            text-align: left;
        }
    }
}

@media (min-width: 600px) {
    .media-product-item-cards {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }
}

@media (min-width: 1000px) {
    .mediaproduct-item-wrapper {
        padding: 10px 70px;
        .no-result {
            width: 96%;
            margin: 20px auto;
        }
    }
}
