@use "src/styles/vars";

.shipment-address-wrapper {
    padding-left: 30px;
    .shipping-item-wrapper {
    
        .radio-input {
            -webkit-appearance: none;
        }
        .payment-item{
            width: 100%;
            display: flex;
            padding: 0;
            margin: auto;
            align-items: center;
            text-transform: uppercase;
            background-color: #f9f5f0;
            font-weight: 600 !important;
            margin-left: 0px !important;

            .delivery-item-and-logo {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                img {
                    height: 20px;
                    margin: 0 20px;
    
                }
            }

          
            
        }
    
        .payment-item:before {
            box-sizing: border-box;
            content: "";
            position: static;
            margin: 24px;
            top: -4px;
            left: 0;
            width: 24px;
            min-width: 24px;
            height: 24px;
            border: 1px solid #d5d5d5;
            border-radius: 12px;
            transition: all .3s ease;
          
        }
        .radio-input:checked+label.payment-item:before {
            background-color: #fff !important;
            border: 8px solid vars.$main-color !important;
        }
    }
}

