@use "src/styles/vars";

.navIconLogo {
    max-width: 20px;
    margin: auto;
    position: relative;
}

.navIcon-wrapper {
    text-align: center;
    cursor: pointer;
    animation: fadeIn 0.8s;

    .navicon-logo-wrapper {
        position: relative;
    }
}
// .navicon-logo-wrapper {
// }

.navIcon-wrapper:not(:last-child) {
    margin-right: 1.8rem;
}
.navIcon-title {
    font-size: 16px;
}

.product-number {
    color: white;
    height: 20px;
    width: 20px;
    position: absolute;
    border-radius: 50%;
    top: 0%;
    left: 55%;

    line-height: 25px;
}
.product-number.wishList {
    background-color: black;
}
.product-number.cart {
    background-color: vars.$main-color;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 1000px) {
    .navIcon-title {
        display: none;
    }
}
