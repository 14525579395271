@use "src/styles/vars";

.common-faqs-wrapper {
    padding: 3% 15%;
    background-color: #f9f5f0;
    font-size: 18px;
    position: relative;
    text-align: start;

    a {
        text-decoration: underline !important;
        color: vars.$main-color !important;
    }

    .common-faqs-title {
        font-weight: 600;
        margin-bottom: 3%;
        font-size: 30px;
        letter-spacing: 3px;
        color: vars.$main-color;
        text-align: center;
    }
    .home-faq-wrapper {
        a {
            text-decoration: underline !important;
            color: vars.$main-color !important;
        }
        .home-faq-button-wrapper {
            width: 15%;
            margin: auto;
            margin-top: 3%;
        }
    }
}

@media (max-width: 600px) {
    .common-faqs-wrapper {
        padding: 0%;
        text-align: justify;

        a {
            text-decoration: underline !important;
            color: vars.$main-color !important;
        }

        .common-faqs-title {
            padding: 6% 3%;
            margin: 0;
        }
        .home-faq-button-wrapper {
            width: 45%;
            padding-top: 7%;
            padding-bottom: 7%;
            margin-top: 0;
        }
    }
}
