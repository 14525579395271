.all-professors-cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

@media (min-width: 1600px) {
    .all-professors-wrapper {
        padding: 2% 10%;
    }
}
