@use "src/styles/vars";

.media-filter-wrapper {
    width: 80%;
    margin: auto;
    padding: 10px 10px;
    background-color: #f9f5f0;
    .filter-media-select-title {
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: space-around;
        text-align: center;
        padding: 7px 0;
        border: solid black 1px;
        text-transform: uppercase;
        .filter-number {
            position: absolute;
            left: 20px;
            bottom: 10px;
            background-color: vars.$main-color;
            color: white;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .open {
            transform: rotate(180deg);
        }
        .closed {
            display: inline-block;
        }
    }
    .filter-items.open {
        max-height: 10rem;
        transition: all 0.3s ease-in-out;
        background-color: white;
        padding: 10px 0;
        .input-wrapper {
            cursor: pointer;
            border-bottom: 1px solid #f9f5f0;
            padding: 7px 20px;
            label {
                cursor: pointer;
                width: 65%;
            }
        }
    }

    .filter-items.closed {
        display: none;
    }
}
