.order-popup-wrapper {
    background: #80b074;
    position: absolute;
    color: #f9f5f0;
    padding: 25px;
    top: 83px;
    right: 0;
    font-size: 16px;
    width: 24%;
    z-index: 3;
    text-align: left;
    margin-right: 0 !important;
    &::before {
        content: "";
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 12px;
        position: absolute;
        top: -20px;
        right: 40px;
        border-color: transparent transparent #80b074 transparent;
    }
    .cross {
        position: absolute;
        top: 5px;
        left: 5px;
        font-size: 10px;
        cursor: pointer;
    }
}

@media (max-width: 600px) {
    .order-popup-wrapper {
        width: 80%;
        top: 75px;
    }
}

@media (min-width: 600px) {
    .order-popup-wrapper {
        width: 30%;
        top: 90px;
        &::before {
            right: 60px;
        }
    }
}
