@use "src/styles/vars";

.filters-sort-under-items-wrapper {
    background-color: #f9f5f0;
    display: flex;
    align-items: center;
    height: 45px;

    label {
        color: inherit;
        cursor: pointer;
    }

    .filters-under-items-input {
        // width: 16px;
        // height: 16px;
    }

    .filters-sort-under-items-input[type="checkbox"] {
        cursor: pointer;
        -webkit-appearance: none;
        appearance: none;
        background-color: #ffffff;
        margin: 0 20px;
        font: inherit;
        color: lightgray;
        width: 17px;
        height: 17px;
        border: 1px solid black;
        border-radius: 0.15em;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
    }

    .filters-sort-under-items-input[type="radio"] {
        cursor: pointer;
        -webkit-appearance: none;
        appearance: none;
        background-color: #ffffff;
        margin: 0 20px;
        font: inherit;
        color: lightgray;
        width: 17px;
        height: 17px;
        border: 1px solid black;
        border-radius: 50%;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
    }

    .filters-sort-under-items-input[type="checkbox"]::before {
        content: "";
        width: 17px;
        height: 17px;
    }
    .filters-sort-under-items-input[type="checkbox"]:checked::before {
        background-color: vars.$main-color;
    }
    .filters-sort-under-items-input[type="checkbox"]:checked + label {
        color: vars.$main-color;
    }

    .filters-sort-under-items-input[type="radio"]:checked {
        background-color: vars.$main-color;
        border: 1px solid vars.$main-color;
    }

    .filters-sort-under-items-input[type="radio"]:checked + label {
        color: vars.$main-color;
    }
}

@media (min-width: 1000px) {
    .filters-sort-under-items-wrapper {
        background-color: white;
        width: 210px;
        height: 40px;
        display: inline-flex;
        text-align: left;
    }
    .choice-items-number {
        color: vars.$main-color;
    }

    .filters-button-wrapper {
        display: none;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
