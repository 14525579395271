@use "src/styles/vars";
.partenaires-wrapper {
    text-align: left;
    background-color: white;
    padding: 50px 300px;
    line-height: 20px;
    h1 {
        color: vars.$main-color;
        text-transform: uppercase;
        font-size: 25px;
        margin-bottom: 70px;
        text-align: center;
    }
    .partenaire-top-description {
        text-indent: 30px;
        margin-bottom: 70px;
        text-align: justify;
    }

    .partenaire-informations-wrapper {
        display: flex;
        margin-bottom: 70px;
        text-align: justify;
        .partenaire-image-wrapper {
            display: flex;
            align-items: center;

            img {
                width: 250px;
                object-fit: cover;
            }
        }
        .partanaire-text-information {
            padding-left: 30px;
            .partenaire-name {
                color: vars.$main-color;
                font-size: 30px;
                font-weight: 600;
                text-transform: uppercase;
            }
            .partenaire-description {
            }
        }
    }
}

@media (max-width: 1000px) {
    .partenaires-wrapper {
        padding: 50px;

        .partenaire-informations-wrapper {
            flex-wrap: wrap;
            margin-bottom: 50px;

            .partenaire-image-wrapper {
                margin: auto;
            }

            .partanaire-text-information {
                padding: 0;
                .partenaire-name {
                    display: none;
                }
            }
        }
    }
}
