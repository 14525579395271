@use "src/styles/vars";

.connexion-wrapper {
    width: 90%;
    height: 90vh;
    margin: auto;
    .connexion-spinner {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #fffcf880;
        top: 0;
        left: 0;
        z-index: 1;
    }
    .connexion-title {
        color: vars.$main-color;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        margin: 40px auto;
    }

    button {
        cursor: pointer;
        border: none;
        background-color: vars.$main-color;
        color: white;
        padding: 10px 30px;
        font-size: 16px;
        text-align: center;
        text-decoration: none;
        font-family: vars.$font;
        margin: 30px auto;
    }
    .new-wrapper {
        margin: 20px 0;
        div {
            display: inline-block;
            margin-right: 20px;
            margin-bottom: 10px;
            a {
                border-bottom: 2px solid black;
            }
        }

        .uppercase {
            text-transform: uppercase;
            font-weight: 600;
        }
    }

    .input-wrapper.checkbox {
        justify-content: flex-start;
    }
    .forget-password {
        text-align: left;
        padding: 0 27px;
        margin-top: 20px;
    }
    .captcha-wrapper {
        width: fit-content;
        margin: 20px auto;
        background: lightgray;
        border-radius: 5px;
        display: flex;

        .input-wrapper.checkbox {
            flex-direction: row-reverse;
            padding: 20px 0;
            label {
                margin-bottom: 0;
            }
            &.capcha.error {
                label {
                    color: #d73307 !important;
                }
            }
            &.validate {
                color: #80b074;
            }
        }
        img {
            width: 37px;
            object-fit: contain;
            padding: 0 14px;
        }
    }
}

@media (min-width: 600px) {
    .connexion-wrapper {
        width: 65% !important;
        left: 20% !important;
    }
}

@media (min-width: 1000px) {
    .connexion-wrapper {
        width: 30% !important;
        left: 36% !important;
    }
}
