@use "src/styles/vars";

.out-stocks {
    background-color: #061f4b;
    color: white;
    width: fit-content;
    padding: 10px;
    margin-bottom: 25px;
    cursor: default !important;
}
.variants-choice-select {
    // margin: 20px 0;
    background-color: #fffcf8;
    padding: 10px 10px;
    margin-bottom: 20px;
    width: 70%;
    cursor: pointer;

    .variants-choice-select-chevron {
        position: absolute;
        right: 32%;
        transform: rotate(270deg);
        font-weight: 600;
    }

    .select {
        font-weight: 600;
    }
}
.variants-choice-popup-background {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;

    .variants-choice-toclose {
        position: absolute;
        right: 4%;
        top: 4%;
        font-size: 20px;
        cursor: pointer;
    }

    .variants-choice-popup-wrapper {
        padding: 20px 20px;
        height: 281px;
        top: 21%;
        left: 7%;
        position: fixed;
        width: 77%;
        background: #fffcf8;
        z-index: 3;
        overflow-y: auto;

        .variants-choice-popup-choose {
            font-size: 18px;
            font-weight: 600;
            color: vars.$main-color;
            // text-transform: uppercase;
        }

        .variants-choices-item {
            border-bottom: 1px solid lightgrey;
            margin: 22px 0;
            padding-bottom: 22px;
            cursor: pointer;
            &.not-in-stock {
                color: lightgrey;
                cursor: default;
            }
        }
        .variants-choices-item-select {
            border-bottom: 1px solid lightgrey;
            margin: 22px 0;
            padding-bottom: 22px;
            font-weight: 600;
            cursor: pointer;
        }
    }
}
.close {
    display: none;
}

@media (min-width: 1000px) {
    .variants-choice-popup-wrapper {
        top: 37% !important;
        left: 32% !important;
        width: 28% !important;
    }
}
