@use "src/styles/vars";

.footer-wrapper {
    color: black;
    padding: 0;
    background-color: #e7dacb;
    height: 100%;
    z-index: 1;
    position: relative;

    // input, button {
    //     -webkit-appearance: none;
    // }

    .footer-top-wrapper {
        padding-top: 50px;
        display: flex;
        width: 100%;
        .footer-univers {
            width: 23%;
            border-right: 1px solid black;
            .footer-logo-wrapper {
                margin-bottom: 20px;
                .footer-logo-img {
                    width: 70%;
                }
            }

            .footer-univers-link {
                font-size: 18px;
                padding: 10px 47px;
                text-align: start;
                a {
                    color: inherit;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .footer-items-wrapper {
            width: 76%;

            .footer-items-colonne-wrapper {
                display: flex;
                width: 100%;

                .footer-items-colonne {
                    text-align: left;
                    padding: 0px 46px;
                    width: 30%;
                    .footer-items-title {
                        margin-bottom: 12px;
                        font-size: 20px;
                        font-weight: 600;
                        text-transform: uppercase;
                        display: block;
                    }

                    .footer-items {
                        display: block;
                        padding: 3px 0;

                        a {
                            color: inherit;
                        }
                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    .footer-item-icon {
                        display: inline-block;
                        .footer-logo {
                            width: 46px;
                            padding: 2px;
                        }
                    }
                    .qualiopi {
                        .footer-logo {
                            width: 200px;
                            height: 110px;
                            padding: 2px;
                        }
                    }
                }
            }

            .footer-items-under {
                margin: 10px 0;
                display: flex;
                justify-content: flex-end;
                width: 100%;

                .footer-item-under-follow-wrapper {
                    padding: 0 80px;
                    display: flex;
                    .footer-item-under-follow-title {
                        text-transform: uppercase;
                        font-size: 18px;
                        font-weight: 600;
                        display: flex;
                        align-items: center;
                        margin-right: 20px;
                    }

                    .newsletter-footer-form {
                        display: inline-block;
                        height: 36px;

                        .input-top-wrapper {
                            height: 38px;

                            .newsletter-footer-input {
                                width: 180px;
                                height: 86%;
                                background-color:#e7dacb;
                                border: 1px solid white;
                                &::placeholder {
                                    color:black;
                                    font-family: vars.$font !important;
                                }
                            }
                        }

                        .newsletter-footer-button {
                            cursor: pointer;
                            height: 100%;
                            width: 140px;
                            text-transform: uppercase;
                            font-family: vars.$font !important;
                            background-color: white;
                            border: 1px solid white;
                            color: black;
                            font-weight: 600;
                        }

                        ul {
                            list-style: none;
                            display: flex;
                            justify-content: space-between;
                            text-align: left;
                            padding-inline-start: 0;
                        }
                    }
                }
            }
        }
    }
    .footer-copyright {
        background-color: #e7dacb;
        padding: 20px;
    }
}
