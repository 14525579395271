@use "src/styles/vars";

.pagination-wrapper {
    display: flex;
    justify-content: center;
    background-color: inherit;
    padding-bottom: 4%;

    a {
        font-family: vars.$font !important;
        font-size: 16px;
    }
}
