.reinssurances-wrapper {
    margin: 50px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .reinssurances-items {
        margin: 0 30px;
        img {
            width: 4rem;
            margin-bottom: 10px;
        }
        div {
            margin: 5px 0;
            font-weight: 600;
        }
    }
}

@media (max-width: 600px) {
    .reinssurances-items {
        img {
            width: 2.5rem !important;
            margin-bottom: 10px;
        }
    }
}
