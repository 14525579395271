.menucard-wrapper {
    position: relative;
    animation: fadeIn 0.8s;
    width: 98%;
    margin: auto;
    margin-bottom: 20px;
    text-align: left;
    text-align: center;
    a {
      .menucard-image-wrapper {
        width: 100%;
        height: 400px;
        position: relative;
         img {
            object-fit: cover !important;
            width: 100%;
            height: 100%;

         };
      }

      .menucard-link-wrapper {
        padding: 20px;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 600;
        a {

        }
      }
    }
}