@use "src/styles/vars";

.App {
    text-align: center;
    font-family: vars.$font;
    letter-spacing: vars.$letter-spacing;
    // @media  (min-width: 2000px) {
    //   padding-left: 13%;
    //   padding-right: 13%;

    // }
    .num-font {
        font-family: vars.$numFont;
    }

    h1,
    h2 {
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }
}

a {
    text-decoration: none;
    color: inherit;
}
// div {
//   position: relative;
// }

.filter-menu-burger-open {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    background-color: #000000b3;
}
.filter-menu-burger-close {
    display: none;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@media (min-width: 2000px) {
    .App {
        // max-width: 1650px;
        // margin: auto;
    }
}
