.seo-prepage {
    text-transform: uppercase;
    font-size: 17px;
    margin: 20px !important;
    line-height: 23px;
}

.alice-carousel__dots {
    position: relative !important;
    border-radius: 0% !important;
    bottom: 0 !important;
    margin: 0 !important;

    .alice-carousel__dots-item.__active {
        background-color: black !important;
    }
    .alice-carousel__dots-item {
        background-color: grey !important;
    }

}

@media (min-width: 1000px) {
    .seo-prepage {
        margin-bottom: 30px !important;
    }
}
