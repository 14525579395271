.loupe-icon {
    max-width: 25px;
    max-height: 25px;
    margin-left: 2.5rem;
}

.searchBar {
    animation: fadeIn 0.8s;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 3;

    .close-search-bar {
        font-size: 25px;
        position: absolute;
        top: -30%;
    }

    .searchBar-form {
        height: 100%;
        width: 100%;
        display: inline-block;

        .searchBar-input {
            height: 100%;
            border: none;
            width: 100%;
            padding-left: 10px;
        }

        .searchBar-input:focus-within {
            border: 1px grey solid;
        }

        .searchBar-icon {
            width: 1.3rem;
            position: absolute;
            top: 26%;
            right: 2%;
            cursor: grab;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
