@use "src/styles/vars";

.empty-page-wrapper {
    background-color: #f9f5f0;
    padding: 20px;
    .first-part,
    .other-part {
        margin: 30px 0;
        font-weight: 600;
    }
    .first-part {
        color: black;
    }
    .other-part {
        color: vars.$main-color;
        span {
            margin-left: 7px;
        }

        a {
            text-decoration: underline;
        }
    }

    .button-link {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        font-size: 15px;

        a {
            padding: 10px;
            margin: 10px 10px;
            background-color: vars.$main-color;
            border: 2px solid vars.$main-color;
            color: white;
            text-transform: uppercase;

            &:hover {
                background-color: white;
                color: vars.$main-color;
            }
        }
    }
    .newsletter-home-wrapper {
        background-color: white;
        .newsletter-home-button {
            width: 20% !important;
        }
    }
}
