@use "src/styles/vars";

.product-found {
    text-align: left;
    padding-top: 30px;
    padding-left: 50px;
    background-color: #f9f5f0;
    margin-top: 1%;
    font-weight: 600;
    color: vars.$main-color;
}
.filters-buttons-wrapper {
    display: flex;
    .filters-button-wrapper {
        width: 50%;
        position: sticky !important;
        max-height: 100vh;
        overflow-y: auto;
        padding-bottom: 0;
        top: 0px;
        z-index: 1;
        background-color: white;
        button {
            width: 100%;
            font-family: vars.$font;
        }
        &.style {
            button {
                background-color: vars.$main-color;
            }
        }
    }
}

.seo-text {
    background-color: #f9f5f0;
    padding-bottom: 4%;
    padding-left: 4%;
    padding-right: 4%;
    text-align: justify;
    text-indent: 30px;
    line-height: 20px;
}
.seo-text h2 {
    position: relative;
    font-size: 0.9em;
    text-indent: 0;
}

.seo-text a {
    color: vars.$main-color;
    text-decoration: underline;
}

.filters-and-tri-wrapper {
    text-align: left;
}

.catalogue-products-wrapper {
    background-color: #f9f5f0;
    padding: 2% 0;
    min-height: 350px;
    .catalogue-product-card-wrapper {
        width: 80%;
        margin: 10% auto;
    }
}

.title-seo {
    padding: 4%;
    font-size: 1em;
}

.catalogue-products-pagination-wrapper {
    background-color: #f9f5f0;
}

@media (min-width: 600px) and (max-width: 1000px) {
    .catalogue-products-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 2% 0;
        .catalogue-product-card-wrapper {
            width: 45% !important;
            margin: 2% 2% !important;
        }
    }
}

@media (min-width: 1000px) {
    .filters-sort-button-wrapper {
        position: relative !important;
        z-index: initial;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        width: 100%;
        overflow-y: initial;
        justify-content: space-between;
        &.style {
            display: none;
        }
    }

    .catalogue-products-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 2% 1%;
        // margin-top: 1%;
        // justify-content: space-around;
        .catalogue-product-card-wrapper {
            width: 23% !important;
            margin: 1% 1% !important;
        }
    }

    .title-seo {
        padding-bottom: 4% !important;
        padding-left: 0% !important;
        padding-right: 0% !important;
        padding-top: 0% !important;
        text-transform: uppercase;
    }
}

.title-seo {
    font-size: 1em;
    background-color: #f9f5f0;
    padding-bottom: 4%;
    font-weight: 600;
}

@media (min-width: 1600px) {
    .catalogue-products-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 2% 10% !important ;
        // margin-top: 1%;
    }

    .title-seo {
        padding-bottom: 4% !important;
    }
}
