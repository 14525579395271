@use "src/styles/vars";

.input-wrapper {
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    .personal-label {
        text-align: left;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 7px;
        padding-left: 7px;
    }
    .personal-input {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid black;
        font-family: vars.$font !important;
        font-size: 15px;
        padding: 7px 7px;
        background-color: #fffcf8;
        &.error {
            border: #d73307 solid 1px;
            background-color: #d7340731 !important;
        }
    }

    .error-message {
        display: none;
        &.error {
            display: block !important;
            color: #d73307;
            margin-top: 5px;
        }
    }

    select {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom-width: 1px;
        font-family: vars.$font !important;
        font-size: 16px;
        padding: 7px 7px;
        background-color: #fffcf8;
        option {
            padding: 2px;
        }
    }

    img {
        width: 20px;
        position: absolute;
        top: 26px;
        right: 25px;
    }

    .personal-input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;
        background-color: #ffffff;
        margin: 0 20px;
        font: inherit;
        color: lightgray;
        width: 17px;
        height: 17px;
        border: 1px solid black;
        border-radius: 0.15em;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
    }

    .personal-input[type="checkbox"]::before {
        content: "";
        width: 17px;
        height: 17px;
    }
    .personal-input[type="checkbox"]:checked::before {
        background-color: vars.$main-color;
    }
}

.input-wrapper.checkbox {
    flex-direction: row;
    justify-content: space-between;
}
