@use "src/styles/vars";

.addresses-wrapper {
    text-align: left;
    padding: 20px;
    width: 100%;
    .spinner-add-address-spinner {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: #ffffff80;
        z-index: 2;
    }
    .addresses-wrapper-top {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .addresses-wrapper-default {
            margin-top: 20px;
            width: 33%;
            background-color: #fffcf8;

            .address-null {
                padding: 50px 20px;
            }

            .adress-card-wrapper {
                width: 90%;
            }
        }
        .addresses-form-add {
            margin-top: 20px;
            background-color: #fffcf8;
            padding: 20px 0;
            width: 65%;

            .address-form-title {
                text-transform: uppercase;
                padding: 10px 0;
                margin: 0 23px;
                color: vars.$main-color;
                font-weight: 600;
                border-bottom: 1px solid vars.$main-color;
            }
            form {
                display: flex;
                flex-wrap: wrap;

                .form-address-left,
                .form-address-right {
                    width: 50%;
                }

                .submit-button {
                    cursor: pointer;
                    border: none;
                    background-color: vars.$main-color;
                    color: white;
                    padding: 10px 30px;
                    font-size: 16px;
                    text-align: center;
                    text-decoration: none;
                    font-family: vars.$font;
                    margin-left: 20px;
                    margin-top: 20px;
                }
                .input-information {
                    padding: 0px 20px;
                    color: gray;
                    margin-bottom: 20px;

                    &.error {
                        color: #d73307 !important;
                    }
                }
            }
        }
    }
    .addresses-wrapper-other {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        background-color: #fffcf8;
    }
}

@media (max-width: 600px) {
    .addresses-wrapper {
        width: 90%;
        form {
            .form-address-left,
            .form-address-right {
                width: 100% !important;
            }
        }

        .addresses-wrapper-default,
        .addresses-form-add {
            width: 100% !important;
        }

        .adress-card-button-wrapper {
            display: grid;
            span {
                margin: 6px !important;
            }
        }
    }
}
