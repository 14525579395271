@use "src/styles/vars";

.filters-button-wrapper {
    animation: fadeIn 0.3s;
    padding: 20px 0;

    .filters-button {
        padding: 15px 15px;
        color: white;
        background-color: rgb(185, 184, 184);
        border: none;
        font-style: vars.$font !important;
    }
}

.filters-button-wrapper-closed {
    display: none;
}

@media (min-width: 1000px) {
    .filters-button-wrapper {
        width: 12%;
        padding-left: 2%;

        .filters-button {
            font-family: vars.$font;
            width: 100%;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
