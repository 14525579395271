.objectifs-wrapper {
    padding-bottom: 15px;
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            padding: 2px;
        }
    }
}

@media (min-width: 1000px) {
    .objectifs-wrapper {
        padding-bottom: 30px;
    }
}
