.mondial-relay-adresse {
    border: 1px solid rgb(150, 21, 74);
    margin-bottom: 4px;
    text-transform: inherit;
    font-size: 13px;
    // background-color:#d3d3d34d;
    width: fit-content;
    font-family: 'Montserrat',Verdana !important;
    padding: 20px;
    .top-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

       
        
        img {
            width: 80px;
        }
    
    }
    .mr-name{
        color: rgb(150, 21, 74);
        font-weight: 700;
    }
    div {
        margin-bottom: 10px !important;
        margin-top: 0px !important;
    }

    .modifier {
        font-weight: 600;
        margin: 0;
        background-color: white;
        border: black solid 1px;
        text-transform: uppercase;
        padding: 5px;
        width: fit-content;
        font-size: 10px;
        cursor: pointer;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
    }
}