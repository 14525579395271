@use "src/styles/vars";

.dropdownMenu {
    a {
        width: 100%;
    }
    text-align: start;
    position: relative;
    cursor: pointer;
    padding-bottom: 5%;
    padding-top: 5%;
    width: fit-content;
}
