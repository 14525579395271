@import "react-alice-carousel/lib/scss/alice-carousel.scss";
.homeSlider-wrapper {
    img {
        object-fit: cover !important;
    }
}
.alice-caroussel {
    height: 600px;
}
//    left button on Caroussel
.alice-carousel__dots {
    width: 99% !important;
    background-color: #f9f5f0;

    .alice-carousel__dots-item {
        background-color: lightgray !important;
        width: 6px !important;
        height: 6px !important;
        border-radius: 0%;
        margin-right: 7px !important;
        border-radius:  0% !important;
        &.__active {
            background-color: rgba(0, 0, 0, 0.69) !important;
        }
    }

    // .alice-carousel__dots-item:not(.__custom) {
    //     background-color: white;
    //     opacity: 1;
    // }

    // .alice-carousel__dots-item:not(.__custom).__active {
    //     background-color: white;
    //     opacity: 0.5;
    // }
}
.alice-carousel__prev-btn-item.__inactive,
.alice-carousel__next-btn-item.__inactive {
    display: none !important;
}

.alice-carousel__next-btn {
    height: 100%;
    width: 2.5% !important;
    position: absolute;
    top: 0%;
    right: 0%;

    .alice-carousel__next-btn-wrapper {
        position: absolute;
        right: 0%;
        top: 0%;
        height: 100%;
        width: 100% !important;

        .alice-carousel__next-btn-item {
            height: 100% !important;
            width: 100% !important;
            position: absolute;
            top: 0%;
            right: 0%;
            font-size: 40px;
            color: rgb(124, 124, 124);
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .alice-carousel__next-btn-item:hover {
            color: black;
        }
    }
}

.alice-carousel__prev-btn {
    height: 100%;
    position: absolute;
    width: 4% !important;
    top: 0%;
    left: 0%;

    .alice-carousel__prev-btn-wrapper {
        height: 100%;

        .alice-carousel__prev-btn-item {
            height: 100% !important;
            width: 100% !important;
            position: absolute;
            top: 0%;
            right: 0%;
            font-size: 40px;
            color: rgb(124, 124, 124);
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .alice-carousel__prev-btn-item:hover {
            color: black;
        }
    }
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
    width: 10%;
}
