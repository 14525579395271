.seo-text-wrapper {
    background-color: #f9f5f0;
    padding: 5% auto;
    // width: 70%;
    position: relative;
    text-align: justify;
    line-height: 25px;
    text-indent: 40px;

    .seo-content-wrapper {
        width: 66%;
        margin: auto;
        padding: 5% 0;

        h1 {
            text-transform: uppercase;
            font-size: 1.3em;
            margin-bottom: 5%;
            text-align: center;
        }
    }
}
