@use "src/styles/vars";

.spinner-wrapper {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: vars.$main-color;
}
