.clients-advices-card-wrapper {
    color: #fff;
    // background-color: lightblue;
    height: 14rem;
    padding: 3% 4%;
    text-align: left;
    margin: 0 2%;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .clients-advices-card-author {
        text-align: end;
    }

    .clients-advices-card-text {
        line-height: 25px;
        text-align: justify;
    }
}

#card-0,
#card-3,
#card-6 {
    background-color: #efac91;
}

#card-1,
#card-4,
#card-7 {
    background-color: #68a2ff;
}

#card-2,
#card-5,
#card-8 {
    background-color: #b2ced8;
}

@media (max-width: 600px) {
    .clients-advices-card-wrapper {
        height: 17rem;
    }
}

@media (min-width: 1600px) {
    .clients-advices-card-wrapper {
        height: 18rem;
    }
}
