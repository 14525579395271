@use "src/styles/vars";

.payment-validate-wrapper {
    min-height: 100vh;
    .text {
        text-transform: uppercase;
        margin: 70px 0;
        font-size: 22px;
        font-weight: 600;
        color: vars.$main-color;
    }

    .text-recap {
        margin: 10px 0;
        font-size: 17px;
    }
    .button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 70px 0;
        .button {
            margin: 0 20px;
            background-color: vars.$main-color;
            color: white;
            text-transform: uppercase;
            padding: 20px;
        }
    }
}
