@use "src/styles/vars";

.total-order-wrapper {

    background-color: #fffcf8;
    margin: 0 20px;
    padding: 0px 30px;
    width: 23%;
    text-align: left;
    position: relative;

    .legals-info {
        font-size: 12px;
        a {
            color: vars.$main-color;
            text-decoration: underline;
        }
    }

    .spinner-wrapper {
        position: absolute;
        background-color: #fffcf850;
    }

    .total-order-title {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 15px;
        margin: 10px 0;
    }
    .price {
        margin-bottom: 7px;
        // font-weight: 600;
        display: flex;
        justify-content: space-between;
    }
    .taxes {
        margin-bottom: 20px;
        color: gray;
    }

    .code-gift-wrapper {
        display: flex;
        justify-content: right;
        align-items: flex-end;
        flex-wrap: wrap;
        font-weight: 600;

        .code-promo {
            // margin-top: 14px;
            width: 100%;
            form {
                margin-top: 10px;
                display: flex;
                margin-left: auto;
                position: relative;

                .cross-delete {
                    width: fit-content;
                    position: absolute;
                    left: 8px;
                    top: 5px;
                    font-size: 15px;
                    color: grey;
                    cursor: pointer;
                }
                input {
                    background-color: #fffcf8;
                    border-radius: none;
                    border: 1px solid black;
                    height: 45px;
                    text-align: center;
                    margin-right : 10px;
                    width: 68%;
                    &::placeholder {
                        text-transform: uppercase;
                    }
                }
                button {
                    cursor: pointer;
                    border-radius: none;
                    background-color: black;
                    color: #fffcf8;
                    height: 48px;
                    width: 30%;
                    font-family: vars.$font !important;
                }
            }
            button {
                cursor: pointer;
                border-radius: none;
                background-color: #fffcf8;
                color: black;
                height: 35px;
                font-family: vars.$font !important;
                // width: 15rem;
            }

            .code-result {
                margin-top: 10px;
            }

            .chose-giftcard-select {
                display: flex;
                flex-direction: column;
                width: 68%;
                margin-right : 10px;
                .cross-delete {
                    width: fit-content;
                    position: absolute;
                    left: 5px;
                    top: 12px;
                    font-size: 15px;
                    color: grey;
                    cursor: pointer;
                    z-index: 1;
                }

                select {
                    position: relative;
                    height: 100%;
                    padding : 0 15px;
                    text-align: center;
                }
                
                option {
                    height: 34px;
                    padding: 10px 20px;
                    font-size: 14px;
                    font-family: vars.$font;
                }

                label {
                    height: 20px;
                    margin-bottom: 7px;
                    height: fit-content;
                }
            }
        }
    }

    .delivery {
        margin-bottom: 10px;
        &.validate {
            font-weight: 600;
            // border-bottom: 1px solid black;
            width: fit-content;
            margin-left: auto;
            color: #68a2ff;
        }
    }

    .total-price-order {
        font-weight: 600;
        font-size: 20px;
        margin: 15px 0;
        text-transform: uppercase;
        border-top: 1px solid black;
        padding-top: 15px;
        display : flex;
        justify-content: space-between;
    }

    .logos-wrapper {
        margin: 10px 0;
        justify-content: left;
        display: flex;
        img {
            // width: 50px;
            height: 25px;
            margin: 0 5px;
            border-radius: 15%;
        }
    }
    .price-infomation {
        margin-top: 10px;
    }
    .paiement-oney {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: left;
        span {
            color: #86bd28;
            margin-left: 5px;
        }
        img {
            width: 70px;
        }
    }
    .order-button {
        // border-top: 1px solid white;
      
        display: block;
        margin: 15px 0 ;
        margin-left: auto;
        background-color: #d99606;
        color: white;
        padding: 18px;
        text-transform: uppercase;
        font-size: 14px;
        text-align: center;
        cursor: not-allowed;
        background-color: lightgray;

        &:not([disabled]) {
            cursor: pointer;
            background-color: #d99606;;
          }
    }

    .product-miniature {
        display : flex;
        div {
            height: 40px;
            margin-right : 10px
        }
        img {
            max-width: 100%;
            max-height: 100%
        }
    }
}

@media (max-width: 1000px) {
    .total-order-wrapper {
        width: 94%;
        margin: 10px 0;
        padding: 10px;
        height: fit-content;


        img {
            height: 17px !important;
        }

        .order-button {
            box-shadow: 0px -4px 4px 0px rgb(105 105 105 / 54%);
            z-index: 3;
            min-height: 25px;
            margin: 0;
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
        }
    }
    .total-price-order {
        font-size: 16px !important;
    }

    .code-promo.gift {
        margin-right: 0px !important;
    }

}
