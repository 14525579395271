@use "src/styles/vars";
.professeur-card-link {
    background-color: #fffcf8;
    margin-bottom: 50px;
    padding-bottom: 15px;
    padding: 0 12px;
    .professeur-card-wrapper {
        width: 100%;

        .professeur-image-wrapper {
            width: 100%;

            img {
                width: 100%;
                height: 350px;
                object-fit: cover;
            }
        }

        .professeur-name {
            text-transform: uppercase;
            font-weight: 600;
            margin: 10px 0;
            font-size: 14px;
        }

        .professeur-subjects {
            padding-bottom: 10px;
            .professeur-card-catalogue-line {
                padding-bottom: 10px;
                width: 45px;
                border-bottom: solid 2px vars.$main-color;
                display: block;
                margin: auto;
            }
        }

        .professeur-bio {
            padding: 20px 20px;
            text-align: left;
        }
        .professeur-card-button-wrapper {
            font-size: 12px;
            margin-bottom: 20px;
            .button {
                color: #fff;
                width: 56%;
                text-transform: uppercase;
                background-color: vars.$main-color;
                padding: 0.9rem;
                margin: auto;
                border: 2px solid vars.$main-color;

                &:hover {
                    color: vars.$main-color !important;
                    background-color: #fff !important;
                }
            }
        }
    }
}

@media (min-width: 600px) {
    .professeur-card-link {
        justify-content: center;
        width: 45%;
    }
}

@media (min-width: 1000px) {
    .professeur-card-link {
        width: 25%;
        box-sizing: border-box;
    }

    .professeur-card-button-wrapper {
        width: 74%;
        margin: auto;
    }
}
