@use "src/styles/vars";

.megamenu-items {
    padding: 16px 30px;
    border-right: 1px solid black;
    text-transform: none;
    text-align: left;
    font-size: 16px;
}

.megamenu-items > * {
    margin-bottom: 1rem;
}

// .megamenu-items:nth-last-child(-n+2)

.megamenu-items:nth-last-child(1) {
    border-right: none;
}
.megamenu-items:last-child {
    background-color: #b2ced8;
}
.megamenu-items-title {
    text-transform: uppercase;
}
.megamenu-items-links a {
    text-decoration: none;
    color: inherit;
    font-weight: normal;
}

.megamenu-items-links a:hover {
    color: vars.$main-color;
}

//astuce css pour paramétrer une propriété en fonction du nombre de frères et soeurs d'un element

// 5 elements
.megamenu-items:nth-child(1):nth-last-child(5),
.megamenu-items:nth-child(2):nth-last-child(4),
.megamenu-items:nth-child(3):nth-last-child(3),
.megamenu-items:nth-child(4):nth-last-child(2),
.megamenu-items:nth-child(5):nth-last-child(1) {
    width: 13%;
}

//4 elements
.megamenu-items:nth-child(1):nth-last-child(4),
.megamenu-items:nth-child(2):nth-last-child(3),
.megamenu-items:nth-child(3):nth-last-child(2),
.megamenu-items:nth-child(4):nth-last-child(1) {
    width: 17.8%;
}

// 3 elements
.megamenu-items:nth-child(1):nth-last-child(3),
.megamenu-items:nth-child(2):nth-last-child(2),
.megamenu-items:nth-child(3):nth-last-child(1) {
    width: 26%;
}
//---------------------------------------------------
