@use "src/styles/vars";

.home-catalogue-taxons-wrapper {
    height: 75%;
    width: 50%;
    padding-bottom: 2%;

    .home-catalogue-taxons {
        font-size: 18px;
        font-weight: 600;

        a {
            color: inherit;
        }

        a {
            color: inherit;
        }
    }
    .home-catalogue-taxons:not(:last-of-type) {
        margin-bottom: 23px;
    }

    .home-catalogue-taxons:hover {
        color: vars.$main-color;
    }
}
