@use "src/styles/vars";

.infos-wrapper {
    width: 100%;
    height: 25%;
    text-transform: uppercase;
    font-weight: 600;
    font-size: vars.$normal-font-size;

    .infos {
        margin-bottom: 3%;
        .infos-icon {
            height: 0.9rem;
            margin-right: 4%;
        }
    }
}
