.lesson-card-selection-price {
    width: 100%;
}
.lesson-card-selection-price > *:first-child {
    margin-right: 25px;
}
.lesson-card-selection-final-price {
    width: 50%;
    color: #d73307;
    font-weight: 800;
    // display: block;
}

.lesson-card-selection-price-without-reduction {
    font-weight: 600;

    // display: block;
}

.lesson-card-selection-before-price {
    text-decoration: line-through;
    // display: block;
}
