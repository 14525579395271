@use "src/styles/vars";

.taxons {
    text-align: left;
    .taxons-name {
        border-bottom: 1px solid rgb(126, 123, 123);
        text-transform: uppercase;
        font-weight: 600;
        padding: 25px 20px;
        background-color: #f9f5f0;
        font-size: 14px;
    }

    .taxons-all {
        padding: 20px 20px;
        color: vars.$main-color;
        font-weight: 600;
        border-bottom: 1px solid lightgrey;
        a {
            color: inherit;
        }
    }

    .taxons-actif-closed {
        display: none;
    }
}

.taxons-group {
    .taxons:last-child {
        .taxons-name {
            background-color: #d73307;
            color: white;
        }
    }
}
