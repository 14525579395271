.level-wrapper {
    display: inline-block;
    margin-top: 7px;
    margin-right: 12px;
    .level-image-wrapper {
        display: inline-block;
        width: 25px;
        margin-right: 5px;
        margin-top: 10px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .level-texte {
        display: inline;
    }
}

@media (min-width: 1600px) {
    .level-wrapper {
        font-size: 23px;
        padding-top: 18px;
    }
}
