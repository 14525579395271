.NavCard-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    min-height: 36rem;
}

@media (max-width: 1000px) {
    .NavCard-wrapper {
        height: fit-content !important;
        .title {
            top: 18%;
        }

        .nav-link-texte {
            padding: 0.6rem;
        }
    }
}




