@use "src/styles/vars";

.header-menu {
    display: flex;
    display: -webkit-flex;
    width: 34%;
    cursor: pointer;

    .header-menu-item {
        margin-right: 1.5rem;
        text-transform: uppercase;
        font-size: vars.$normal-font-size;
        font-weight: 700;
    }
}
.header-menu-item:hover {
    color: vars.$main-color;
}
.navBar-mutual {
    display: flex;
    justify-content: right;
    width: 32%;
    height: 100%;
    align-items: center;
    height: 2.5rem;
    #user {
        height: 100%;
        padding: 0%;
        display: flex;
    }

    & > *:not(:last-child) {
        margin-right: 1.8rem;
    }
}

@media (min-width: 600px) and (max-width: 1000px) {
    .navBar-mutual {
        display: flex;
        justify-content: right;
        margin-left: 70%;
        height: 100%;
        align-items: center;
        height: 2.5rem;
        width: 30%;
        .dropdownMenu {
            margin: auto;
            padding: 0;
        }
    }
}
@media (max-width: 600px) {
    .navBar-mutual {
        display: flex;
        justify-content: right;
        margin-left: 50%;
        height: 100%;
        align-items: center;
        height: 2.5rem;
        width: 50%;
        .dropdownMenu {
            margin: auto;
        }
    }
}
