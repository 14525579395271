@use "src/styles/vars";

.under-navbar {
    background-color: #f9f5f0;
    color: #1d1d1b;
    font-weight: 600;
    width: 100%;
    height: 3.8rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-transform: uppercase;
    z-index: 1 !important;
    position: sticky;
    top: 0;
    font-size: 17px;
}

@media (max-width: 1000px) {
    .under-navbar-searchBar {
        width: 90%;
    }
}
