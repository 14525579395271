@use "src/styles/vars";

.header-mutual,
.header-mutual * {
    z-index: 3;
}
.header-mutual {
    max-width: 100%;
    font-size: vars.$paragraph-header;
    position: sticky;
    top: 36px;
    background-color: #fff;

    .header-mutual-wrapper {
        animation: smoothReverse 0.4s;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 2rem;
        height: 4rem;
    }

    .header-menu-item:last-child {
        color: #6a8ea4;
    }

    .header-menu {
        width: 32%;
    }
    .header-menu-item {
        font-size: 11px;
    }

    .header-menu-item a {
        color: inherit;
    }

    .header-mutual-wrapper-scroll {
        animation: smooth 0.4s;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 2rem;
        height: 4rem;
    }
}

@keyframes smooth {
    0% {
        height: 7rem;
    }
    100% {
        height: 5rem;
    }
}

@keyframes smoothReverse {
    0% {
        height: 5rem;
    }
    100% {
        height: 7rem;
    }
}

//Responsive

@media (max-width: 1000px) {
    .header-mutual {
        position: sticky;
        font-size: 18px;
        top: 0px;

        .header-mutual-wrapper {
            animation: none;
            flex-wrap: wrap-reverse;
            height: 7rem;

            .header-menu {
                display: none;
            }
        }

        .height {
            height: 3rem !important;
        }

        .header-mutual-wrapper-scroll {
            animation: none;
            flex-wrap: wrap-reverse;
            height: 7rem;

            a {
                width: fit-content;
                margin: auto;
            }

            .header-menu {
                display: none;
            }
        }
    }
}
