@use "src/styles/vars";


.maincard-wrapper {
    width: 45%;
    background-color: vars.$main-color;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    

    .maincard-container {
        display: grid; /* La première colonne s'ajuste au contenu, limitée à 200px */
        gap: 10px;       
        max-width: fit-content;
        min-width: 80%;

        .searchBar {
            width: 74%;
            margin: auto;
            height: 45px;
            z-index: 2;
            button {
                width: 1.3rem;
                position: absolute;
                top: 18%;
                right: 0;
                background-color: white;
                cursor: pointer;
                border: none;
                height: 2rem;
            }
            input {
                width: 98%;
            }
        }
        h1 {
            width: fit-content;
            text-transform: uppercase;
            font-weight: 800;
            color: white;
            text-align: left;
            font-size: 45px;
            margin: auto  !important;
            max-width: 75%;
            p{
                margin: 0;
            }
    
        }
    
        .description {
            width: 60%;
            margin: 20px auto;
            text-align: justify;
            grid-column: 1;
        }
    }

   
}

@media (max-width: 1000px) {
    .maincard-wrapper {
        width: 100%;
        padding: 30px 0;

        h1 {
            font-size: 35px !important;
        }

        .searchBar {
            width: 90% !important;
        }
    }
}

@media (min-width: 2000px) {
    .maincard-wrapper {

        h1 {
            font-size: 65px !important;
        }

    }
}
