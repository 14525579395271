.mediaproducts-wrapper {
    padding: 10px 0;
    background-color: #f9f5f0;
    min-height: 100vh;

    .no-result {
        margin-top: 20px;
    }

    .media-products-spinner {
        height: 100vh;
    }

    .media-searchBar {
        width: 80%;
        margin: 20px auto;
        height: 34px;

        input {
            width: 97%;
        }

        .search-bar-button {
            width: 1.3rem;
            position: absolute;
            top: 13%;
            right: 0;
            cursor: pointer;
            border: none;
            height: 2rem;
            background-color: inherit;
        }
    }
}

@media (min-width: 1000px) {
    .media-searchBar {
        width: 35% !important;
    }
}
