@use "src/styles/vars";

.home-soul-wrapper {
    margin: 3% auto;
    width: 70%;
    position: relative;
    height: 100%;

    .home-soul-title-second-part {
        display: none;
    }

    .home-soul-title-first-part {
        margin-left: 20%;
        font-size: 65px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 6px;
        color: vars.$main-color;
        text-align: start;
    }

    .home-soul-wrapper-sides {
        width: 100%;
        display: flex;
        justify-content: center;

        .home-soul-wrapper-left {
            height: 100%;

            .home-soul-wrapper-left-image {
                object-fit: cover;
                width: 370px;
                height: 571px;
                &.mobile {
                    display: none !important;
                }
                &.desktop {
                    display: block !important;
                }
            }

            .home-soul-wrapper-left-paragraph {
                text-align: left;
                margin-top: 1%;
            }
        }

        .home-soul-wrapper-right {
            margin-left: 3%;
            text-align: justify;
            line-height: 20px;

            .home-soul-title-second-part {
                margin-bottom: 4%;
                font-size: 33px;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 6px;
                color: vars.$main-color;
                text-align: start;
                display: block;
            }

            .home-soul-wrapper-right-text {
                .home-soul-first-part,
                .home-soul-second-part {
                    display: block;

                    .home-soul-wrapper-right-text-title {
                        margin-top: 3% !important;
                        margin-bottom: 1%;
                        margin-block-start: 0;
                        margin-block-end: 0;
                        margin-inline-start: 0px;
                        margin-inline-end: 0px;
                        // font-size: 1em;
                        position: static !important;
                        text-transform: none;
                    }

                    .home-soul-wrapper-right-text-paragraph {
                        margin-top: 2%;
                        font-size: 17px;
                        text-indent: 40px;
                    }
                }
                .home-soul-button {
                    display: none;
                }
            }
        }
    }

    .home-soul-wrapper-image {
        text-align: end;
        width: 100%;
        margin: 3% 0;

        .home-soul-image {
            width: 910px;
            height: 400px;
            object-fit: cover;
        }
    }
}

@media (max-width: 1000px) {
    .home-soul-wrapper {
        width: 88%;
        padding: 14% 5%;

        .home-soul-title-first-part {
            margin-left: 0;
            text-align: center;
        }

        .home-soul-title-second-part {
            display: block;
            margin-bottom: 4%;
            font-size: 33px;
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 6px;
            color: vars.$main-color;
            text-align: center;
        }

        .home-soul-wrapper-sides {
            display: block;
            .home-soul-wrapper-right {
                .home-soul-wrapper-right-text {
                    .home-soul-wrapper-right-text-title {
                        text-transform: none;
                        position: static;
                    }

                    .home-soul-second-part {
                        display: none;
                    }
                    .home-soul-second-part-open {
                        display: block;
                    }

                    .home-soul-button {
                        display: block;
                        background-color: white;
                        border: none;
                        font-weight: 600;
                        margin: 20px auto;
                        font-family: vars.$font;
                    }
                }
                .home-soul-title-second-part {
                    display: none;
                }
            }

            .home-soul-wrapper-left {
                .home-soul-wrapper-left-image {
                    width: 309px;
                    &.mobile {
                        margin: auto;
                        display: block !important;
                    }
                    &.desktop {
                        display: none !important;
                    }
                }
            }
        }
    }
}
