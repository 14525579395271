@use "src/styles/vars";

.extrait-cours-wrapper {
    .extrait-cours-title {
        padding: 15px;
        text-transform: uppercase;
        font-size: 23px;
        color: vars.$main-color;
        font-weight: 600;
    }
    .extrait-cours-player-wrapper {
        height: 233px;
    }

    .player-logo {
        width: 80px;
    }
}

@media (min-width: 1000px) {
    .extrait-cours-wrapper {
        width: 85%;
        padding: 3% 0%;
        margin: auto;

        .extrait-cours-title {
            font-size: 28px;
            div {
                margin-bottom: 10px;
            }
        }
    }

    .extrait-cours-player-wrapper {
        height: 500px !important;
    }

    .player-logo {
        width: 20% !important;
    }
}

@media (min-width: 1600px) {
    .extrait-cours-wrapper {
        width: 73%;
    }
    .extrait-cours-player-wrapper {
        height: 820px !important;
    }
}
