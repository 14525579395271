.under-taxons {
    .under-taxons-title {
        padding: 20px 20px;
        font-weight: 600;
    }
    // .under-taxons-title:last-child {
    //    background-color: #B2CED8;

    // }

    .under-taxons-actif-closed {
        display: none;
    }
    // #offre {
    //     color: #6C95A3;
    // }
}
