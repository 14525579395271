.navBar {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 2.5rem;
    .navlink-wrapper {
        width: fit-content;
        height: fit-content;
        margin-right: 1.8rem;
        margin-left: 0 !important;
    }
}

@media (max-width: 600px) {
    .navBar {
        width: 100%;
    }
}
