@use "src/styles/vars";

.home-clients-advices-wrapper {
    margin: 6% 2%;

    .alice-carousel__dots {
        display: none;
    }

    .alice-carousel:nth-child(3n + 1) {
        background-color: lightcoral;
    }
    .home-clients-advices-title {
        text-transform: uppercase;
        font-size: 30px;
        letter-spacing: 5px;
        font-weight: 600;
        margin-bottom: 2%;
        color: vars.$main-color;
    }
}
.home-clients-advices-wrapper .alice-carousel__prev-btn-item {
    right: 27% !important;
}

.home-clients-advices-wrapper .alice-carousel__next-btn-item {
    left: 23% !important;
}

@media (max-width: 600px) {
    .home-clients-advices-wrapper {
        margin: 12% 2%;
    }
    .home-clients-advices-title {
        font-size: 23px !important ;
        margin: 10% 0 !important;
    }
}

@media (min-width: 1600px) {
    .home-clients-advices-wrapper {
        padding: 0 15%;
    }
}
