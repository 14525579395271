.reinssurance-product-wrapper {
    // text-transform: none;
    padding-bottom: 20px;
    display: flex;
    width: 97%;

    a {
        width: 33%;
        line-height: 17px;
        padding: 0;
        display: block;
        font-size: 12px;

        .reinssurance-items {
            width: 100%;

            img {
                height: 4rem;
            }

            .catchPhrase-start {
                margin: 0;
            }
        }
    }
}

@media (min-width: 1000px) {
    .fiche-product-wrapper {
        position: relative;
        background-color: #f9f5f0;
        width: 65%;

        .reinssurance-product-wrapper {
            width: 100%;
            background-color: white;
        }
        .barre-fixed-wrapper-desktop-scroll {
            background-color: #fff;
            position: fixed;
            animation: smooth 0.2s;
            top: 191px;
            right: 0;
            height: 100%;
            width: 35%;
            z-index: 1;
        }

        .barre-fixed-wrapper-desktop {
            background-color: #fff;
            top: 200px;
            position: fixed;
            animation: smoothReverse 0.2s;
            right: 0;
            height: 100%;
            width: 35%;
            z-index: 3;
        }

        .reinssurance-product-wrapper {
            padding-top: 15px;
            padding-bottom: 40px;
        }
    }
}

@media (min-width: 600px) and (max-width: 1000px) {
    .fiche-product-wrapper {
        background-color: #f9f5f0;
        // padding: 0 100px;
    }
}

@keyframes smooth {
    0% {
        top: 200px;
    }
    100% {
        top: 150px;
    }
}

@keyframes smoothReverse {
    0% {
        top: 150px;
    }
    100% {
        top: 200px;
    }
}
