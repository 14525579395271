@use "src/styles/vars";

.burger-wrapper {
    width: 30px;
    top: 25px;
    left: 15px;
    border-radius: 3px;
    background-color: rgb(252, 252, 252);
    position: absolute;
    display: block;
    z-index: 3;

    .burger-item {
        border-bottom: 2px solid rgb(150, 140, 140);
    }
    .burger-item:not(:last-of-type) {
        margin-bottom: 10px;
    }
}
.menu-burger-items-wrapper {
    display: none;
}

.menu-burger-wrapper-open {
    background-color: white;
    position: fixed;
    top: 0;
    z-index: 15 !important;
    width: 100%;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);

    .burger-background {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.322);
        position: fixed;
        left: 0;
        z-index: -1;
    }

    .menu-burger-navIcons {
        font-size: 15px;
        display: flex;
        flex-wrap: wrap;
        padding: 3% 20%;
        a {
            width: 50%;
            margin: 7px 0;
        }
    }

    .burger-wrapper-open {
        width: 12px;
        padding: 12px 12px;
        margin: 2% 2%;
        border-radius: 3px;
        background-color: rgb(240, 242, 243);
        position: absolute;
        display: block;
        z-index: 3;
        right: 0;

        .burger-item-open {
            border-bottom: 2px solid rgb(121, 113, 113);
        }
        .burger-item-open.item-one {
            transform: rotate(-45deg) translate(-3px, 3px);
        }
        .burger-item-open.item-three {
            transform: rotate(45deg) translate(-5px, -6px);
        }

        .burger-item-open.item-two {
            display: none;
        }
        .burger-item-open:not(:last-of-type) {
            margin-bottom: 10px;
        }
    }

    .menu-burger-items-wrapper-open {
        width: 100%;
        height: 100%;
    }
}
