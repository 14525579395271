@use "src/styles/vars";

.simple-button {
    color: #fff;
    width: 100%;
    text-transform: uppercase;
    background-color: vars.$main-color;
    padding: 0.9rem;
    margin: auto;
    border: 3px solid vars.$main-color;
    cursor: pointer;
    &:hover {
        color: vars.$main-color !important;
        background-color: #fff !important;
        border: 3px solid vars.$main-color !important;
    }
}
