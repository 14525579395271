.accountAccess {
    margin-right: 1.5rem;
    animation: fadeIn 0.8s;
    position: relative;
    .accountAccess-image {
        max-width: 20px;
        margin: auto;
        position: relative;
    }

    .firstname {
        font-size: 15px;
        position: absolute;
        bottom: -20px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
