@use "src/styles/vars";

.gift-card-wrapper {
    padding: 30px;
    width: 100%;
    min-height: 50vh;

    .empty-gift-cards {
        text-align: justify;
        div {
            margin: 20px 0;
        }
    }
    .giftcard-input-title {
        font-size: 17px;
        border-bottom: 1px solid black;
        width: fit-content;
        margin: auto;
        font-weight: 800;
    }
    .empty-gift-cards {
        margin: 50px auto;
        font-weight: 600;
        width: fit-content;
    }
    .gift-cards-element-wrapper {
        padding: 20px;
        background-color: #fffcf8;
        margin: 30px 0;
        border-bottom: 1px solid black;
        position: relative;

        .spinner-wrapper {
            position: absolute;
            top: 0;
            height: 100%;
            background: #fffdf852;
        }

        .gift-cards-element {
            margin-bottom: 20px;

            .icon {
                max-width: 30px;
                cursor: pointer;
            }

            .enabled {
                font-weight: 800;
                color: #80b074;
            }
            .disabled {
                font-weight: 800;
                color: #d73307;
            }

            .gift-cards-element-title {
                font-weight: 600;
                margin: 7px 0;
            }

            div {
                text-align: left;
            }
        }

        .gift-cards-element.code {
            color: vars.$main-color;
        }
    }

    .gift-add-form {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;

        .input-wrapper {
            width: 80%;
            padding: 20px 0;
        }

        button {
            height: 34px;
            margin: 0px 20px;
            cursor: pointer;
            border: none;
            text-transform: uppercase;
            background-color: vars.$main-color;
            color: white;
            padding: 10px 20px;
            font-size: 15px;
            text-align: center;
            text-decoration: none;
            font-family: vars.$font;
        }
    }
}

@media (min-width: 1000px) {
    .gift-card-wrapper {
        min-height: 100vh;

        .giftcard-input-title {
            margin: 0;
        }

        .gift-cards-element-wrapper {
            display: flex;
            justify-content: space-evenly;
        }

        .gift-add-form {
            button {
                margin: 20px;
            }
        }
    }
}
