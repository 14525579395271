@use "src/styles/vars";

.cours-ideal-wrapper {

    .progress-bar {
        width: 100%;
        height: 2vh;
   
        .progress-bar-color {
            background-color: vars.$main-color;
            height: 100%;
            transition: width 1s ease;
            &.fadeIn {
                animation: fadeIn 1s ease-in-out forwards; // Animation fade-in
            }
            
        }

     }

     @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    .cours-ideal-side-wrapper{
        min-height: 90vh;
        background-color: #f9f5f0;
        display: flex;
    

        .spinner-wrapper {
            height:62vh;
        }
    
        .side-wrapper {
            width: 50%;
            display: flex;
            align-items: center;
            img {
                max-width: 100%;
                height: 100%;
                object-fit: cover
            };
        }
    
        .cours-ideal-title {
            border-bottom: 2px solid #000;
            font-size: 20px;
            font-weight: 600;
            margin: 40px auto;
            text-transform: uppercase;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
        }
    
        .cours-ideal-data {
            width:100%;
            .cours-ideal-choices {
                justify-content: center;
                width: 100%;
                display: flex;
                // flex-direction: column;
            }
    
            .cours-ideal-question {
                font-size: 20px;
                color: vars.$main-color;
                font-weight: 600;
                margin: 30px auto;
            }
    
            .cours-ideal-choices {
                cursor: pointer;
                font-size: 18px;
                font-weight: 600;
                margin: 15px auto;
                padding: 10px;
                width: 60%;
                color: black;
                background-color: #fffcf8;
    
                &:hover {
                    transition: all 0.2s ease-in-out;
                    color: white;
                    background-color: #061f4b;
                }
            }
    
            .precedent-button {
                cursor: pointer;
                background-color: vars.$main-color;
                color: white;
                padding: 10px 30px;
                width: fit-content;
                text-align: center;
                margin: 30px auto;
            }
            .cours-ideal-product-card-wrapper {
                width: 80%;
                margin: 10% auto;
            }
        }
    }
}


@media (min-width: 600px) and (max-width: 1000px) {
    .cours-ideal-products-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 2% 0;
        .cours-ideal-product-card-wrapper {
            width: 45% !important;
            margin: 2% 2% !important;
        }
    }
}

@media (min-width: 1000px) {
    .cours-ideal-products-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 2% 1%;
        justify-content: center;
        // margin-top: 1%;
        // justify-content: space-around;
        .cours-ideal-product-card-wrapper {
            width: 23% !important;
            margin: 1% 1% !important;
        }
    }
}

@media (min-width: 1600px) {
    .cours-ideal-product-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 2% 10% !important ;
        // margin-top: 1%;
    }
}
