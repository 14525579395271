.oney-choose-wrapper {
    // position: absolute;
    background-color: white;
    margin: 0 !important;
    line-height: 1.25;
    font-family: Poppins, Arial, sans-serif !important;

    .oney-choose-tab {
        margin: 0 !important;
        // justify-content: space-between;
        display: flex;

        .oney-choose-tab_choice {
            width: 50%;
            margin: 0 !important;
            cursor: pointer;
            .oney-choose-tab_choice_item {
                text-align: center;
                border: 1px solid #ccc;
                border-bottom: 0;
                flex: 1;
                padding: 1rem 8px;
                margin: 0;
            }
            img {
                max-width: 4.5rem;
                margin: 2px;
            }
            p {
                margin: 0;
                font-weight: normal !important;
            }
        }
    }

    .oney-choose-cards {
        margin: 0 !important;
        // padding: 1em;
        .oney-choose-cards-item {
            margin: 0;
            display: block;

            .oney-choose-cards-item_content {
                display: none;
                border-color: #ccc;
                height: 100%;
                border: 1px solid #ccc;
                flex-direction: column;
                transition:
                    border-color,
                    background-color 0.3s ease-in-out;
                position: relative;
                box-shadow: 0 1px 2px #22242626;
                margin-top: 0 !important;
                // padding: 1em !important;
                display: flex !important;

                .oney-choose-cards-item_header {
                    display: none;
                }

                .oney-choose-cards-item_choice_content {
                    p {
                        color: #000;
                        border-bottom: 1px solid #ccc;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        display: flex;
                        margin: 0 !important;
                        padding: 0.75rem !important;
                        font-weight: normal !important;
                    }
                    small {
                        display: block;
                    }
                }
            }
        }
    }
    .oney-choose-tab .active {
        border-bottom: 5px solid #81bc00;
        background-color: #81bc0021;
    }
}
